import { IndividualDataItem } from '../utils/numbers'
import { INCEPTION_MONTH_COLUMN_NAME } from '../components/renewal-graph/gwp-bar-chart/inception-month-graph'
import {
  ADJUSTED_CONVEX_SHARE_NEW_PREMIUM_AMOUNT,
  ADJUSTED_GROSS_RATE_NEW_PREMIUM_AMOUNT,
  ADJUSTED_PREMIUM_VALUE,
  ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION,
  ADJUSTMENT_ID,
  ADJUSTMENT_NAME,
  PREMIUM_COLUMN_NAME,
} from './calculate-kpis'
import { WayfinderDataType, WF_TYPE_OF_DATA_COLUMN } from './TypeOfData'

export const PREVIOUS_SUBMISSION_REF_IDENTIFIER = 'Previous Convex Submission Ref'
export const RENEWAL_INCEPTION_MONTH_COLUMN_NAME = 'Renewal Inception Month'
export const A1_MARKET_COLUMN_NAME = 'A1 Market'

export function calculateWhichActualDataIsARenewal(rawActualData: any[]): any[] {
  return rawActualData.filter((item) => item[PREVIOUS_SUBMISSION_REF_IDENTIFIER])
}

export function calculateWhichActualDataIsNew(rawActualData: any[]): any[] {
  return rawActualData.filter((item) => !item[PREVIOUS_SUBMISSION_REF_IDENTIFIER])
}

export function calculateDataWithActuals(rawData: any[], rawActualData: any[]) {
  if (!rawActualData || rawActualData.length === 0) {
    return rawData
  }

  const marketsIncludedInRawData = calculateIncludedMarkets(rawData)
  const rawActualDataWithoutExcludedMarkets = rawActualData.filter((rawActualDataItem) =>
    marketsIncludedInRawData.includes(rawActualDataItem[A1_MARKET_COLUMN_NAME]),
  )

  const monthsIncludedInRawActuals = calculateIncludedMonths(rawActualDataWithoutExcludedMarkets)
  const rawDataWithOverlap = rawData.filter((rawDataItem) =>
    monthsIncludedInRawActuals.includes(rawDataItem[RENEWAL_INCEPTION_MONTH_COLUMN_NAME]),
  )
  const rawDataWithoutOverlap = rawData.filter(
    (rawDataItem) => !monthsIncludedInRawActuals.includes(rawDataItem[RENEWAL_INCEPTION_MONTH_COLUMN_NAME]),
  )
  const adjustedDataWhichOverlappedWithActuals = adjustRawDataWhichHasAnOverlap(rawDataWithOverlap)

  const actualDataWithModifiedColumns = updateActualDataSoItWorks(rawActualDataWithoutExcludedMarkets)
  return [...rawDataWithoutOverlap, ...adjustedDataWhichOverlappedWithActuals, ...actualDataWithModifiedColumns]
}

export const isNotReplacedByActuals = (dataItem: IndividualDataItem) => {
  return dataItem[ADJUSTMENT_ID] !== OVERRIDDEN_BY_ACTUALS
}

export const isNotActuals = (dataItem: any) => {
  return dataItem[ADJUSTMENT_ID] !== ACTUALS_ADJUSTMENT_ID
}

export const isNotActualId = (id: string) => {
  return id !== ACTUALS_ADJUSTMENT_ID
}

export const OVERRIDDEN_BY_ACTUALS = 'Overridden By Actuals'
export const ACTUALS_ADJUSTMENT_ID = 'Actuals'

function adjustRawDataWhichHasAnOverlap(rawDataWhichOverlaps: any[]): any[] {
  return rawDataWhichOverlaps.map((rawDataItem) => {
    return {
      ...rawDataItem,
      [ADJUSTED_PREMIUM_VALUE]: '0',
      [ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION]: '0',
      [ADJUSTMENT_ID]: OVERRIDDEN_BY_ACTUALS,
      [ADJUSTMENT_NAME]: OVERRIDDEN_BY_ACTUALS,
    }
  })
}

export function updateActualDataSoItWorks(rawActualData: any[]): any[] {
  return rawActualData.map((rawActualDataItem) => {
    const originalPremium = rawActualDataItem[PREMIUM_COLUMN_NAME]

    return {
      ...rawActualDataItem,
      [PREMIUM_COLUMN_NAME]: '0',
      [ADJUSTED_CONVEX_SHARE_NEW_PREMIUM_AMOUNT]: originalPremium,
      [ADJUSTED_GROSS_RATE_NEW_PREMIUM_AMOUNT]: originalPremium,
      [ADJUSTED_PREMIUM_VALUE]: originalPremium,
      [ADJUSTED_PREMIUM_VALUE_WITH_BASIC_RETENTION]: originalPremium,
      [ADJUSTMENT_ID]: ACTUALS_ADJUSTMENT_ID,
      [ADJUSTMENT_NAME]: ACTUALS_ADJUSTMENT_ID,
      [WF_TYPE_OF_DATA_COLUMN]: WayfinderDataType.ACTUALS,
    }
  })
}

function calculateIncludedMonths(data: any[]) {
  return Array.from(new Set(data.map((item) => item[INCEPTION_MONTH_COLUMN_NAME])))
}

function calculateIncludedMarkets(data: any[]) {
  return Array.from(new Set(data.map((item) => item[A1_MARKET_COLUMN_NAME])))
}
