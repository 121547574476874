import { useCallback, useContext, useEffect, useState } from 'react'
import { useQueryParam } from 'use-query-params'
import { BooleanParam } from 'serialize-query-params'
import { Button } from '../../components/react_library_button/Button'

import './Client.scss'

import { Adjustments } from '../../components/adjustments/Adjustments'
import FiltersContainer from '../../components/filters-container/FiltersContainer'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { FilterContext, transformIntoFlatStructure } from '../../providers/FilterProvider'
import SelectScenarioMessage from '../../components/select-scenario-message/SelectScenarioMessage'
import { MultiSelectDropdown } from '../../components/multi-select-dropdown/MultiSelectDropdown'
import SplitContentContainerWithSideMenu from '../../components/split-content-container-with-side-menu/SplitContentContainerWithSideMenu'
import { onSelectSingleValueFromMulti } from '../scenario-chooser/ScenarioChooser'
import KpiBar from '../../components/kpi-bar/KpiBar'
import { FullKpiDisplay } from '../../components/kpis/FullKpiDisplay'
import SectionHeader from '../../components/section-header/SectionHeader'
import { ScenarioStatus } from '../../utils/status/ScenarioStatus'
import { PREVIEW_ID } from '../../backend/adjustments'
import { CreateAdjustmentCard } from '../../components/create-adjustment-card/CreateAdjustmentCard'
import {
  RenewalAdjustmentContext,
  RenewalAdjustmentsContextContainer,
} from '../../providers/RenewalAdjustmentsProvider'
import { onNumberInputUpdateState } from '../../utils/onChange'
import ClientGraphs, { CLIENT_PARENT_NAME_COLUMN } from './ClientGraphs'
import TextField from '../../components/text-field/WFTextField'
import { PossiblyNegativeNumber, sumListAsIntegers } from '../../utils/numbers'
import {
  CLIENT_NAME_COLUMN_IDENTIFIER,
  filterData,
  OLD_CLIENT_NAME_COLUMN_IDENTIFIER,
  PREMIUM_COLUMN_NAME,
  REQUIREMENT_NAME_COLUMN_IDENTIFIER,
} from '../../backend/calculate-kpis'
import SegmentedControl from '../../components/segmented-control/SegmentedControl'
import { formatEnumForDisplay } from '../../components/ScenarioNavigationMenuItem/ScenarioNavigationMenuItem'
import ColumnAndValueFilter from '../../components/column-and-value-filter/ColumnAndValueFilter'
import { GraphFilterDataContext } from '../../components/renewal-graph/GraphFilterDataProvider'
import { FilterType, NameListFilter } from '../../backend/calculate-possible-filter-values'
import { removeLowerFilter } from '../../components/scenario-filter-card/ScenarioFilterCard'
import { groupListOfObjsBy, sortListBy, toOption } from '../../utils/lists'
import { FieldToGroupPortfolioBy } from '../../providers/PortfolioGroupDropdownAndProvider'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { AppQueryContext } from 'src/providers/AppQueryProvider'
import { RenewalTypes } from '../renewal/RenewalTypes.enum'
import {
  CLIENT_NAME_FILTER_ID,
  CLIENT_PARENT_FILTER_ID,
  LAYER_REF_FILTER_ID,
  REQUIREMENT_NAME_FILTER_ID,
} from './filterIds'
import { ManyDropdownFilterValues } from '../../providers/FilterProvider/model'

function getListOfClientsFromColumn(data: any[], columnName: string) {
  const clientNamesAndAssociatedData = groupListOfObjsBy(data, columnName)
  const clientNamesAndTotalOriginalGwpAsArray: Array<{ clientName: string; total: number }> = Object.entries(
    clientNamesAndAssociatedData,
  ).map(([clientName, listOfAssociatedData]) => ({
    clientName,
    total: sumListAsIntegers(listOfAssociatedData, PREMIUM_COLUMN_NAME).toNumber(),
  }))

  const sortedListOfNames = sortListBy(clientNamesAndTotalOriginalGwpAsArray, ['total'])
    .reverse()
    .map((item) => item.clientName)

  return removeDodgyValuesFromClientList(sortedListOfNames)
}

function removeDodgyValuesFromClientList(sortedListOfNames: string[]): Array<string> {
  return sortedListOfNames
    .filter((item) => Boolean(item))
    .filter((item) => item !== '0')
    .filter((item) => item !== 'Placeholder')
    .filter((item) => item !== 'undefined')
}

export function ClientSpecificContent() {
  const [graphPanelIsOpen] = useQueryParam('graphPanel', BooleanParam)

  const { businessQuery } = useContext(AppQueryContext)
  const { fullDataForScenario } = useContext(FullScenarioDataContext)
  const { possibleFilterValues } = useContext(GraphFilterDataContext)
  const { currentScenario } = useContext(ScenarioContext)
  const { triggerReloadSavedAdjustments, currentlyEditingAdjustmentId, setCurrentDefaultAdjustmentNameToUse } =
    useContext<RenewalAdjustmentsContextContainer>(RenewalAdjustmentContext)
  const { dropdownFilterOptions, onChangeFilters } = useContext(FilterContext)

  const [availableClients, setAvailableClients] = useState<string[]>([])
  const [clientNameColumnToUse, setClientNameColumnToUse] = useState<string>(CLIENT_NAME_COLUMN_IDENTIFIER)
  const [availableClientParents, setAvailableClientParents] = useState<string[]>([])
  const [availableRequirements, setAvailableRequirements] = useState<string[]>([])
  const [availableLayerRefs, setAvailableLayerRefs] = useState<string[]>([])
  const [numberOfClientsToDisplay, setNumberOfClientsToDisplay] = useState<PossiblyNegativeNumber>(25)

  const [currentBusinessType, setBusinessType] = businessQuery

  const currentlyChosenName = (dropdownFilterOptions?.[CLIENT_NAME_FILTER_ID]?.chosenValue as string[])?.[0]
  const currentlyChosenRequirements = dropdownFilterOptions?.[REQUIREMENT_NAME_FILTER_ID]?.chosenValue as string[]
  const currentlyChosenRequirementsAsStringForRerenderingReaons = JSON.stringify(currentlyChosenRequirements)

  const onChangeClientName = useCallback(
    (value: string) => {
      onChangeFilters({
        [CLIENT_NAME_FILTER_ID]: {
          columnName: clientNameColumnToUse,
          chosenValue: [value],
        },
        [CLIENT_PARENT_FILTER_ID]: dropdownFilterOptions[CLIENT_PARENT_FILTER_ID],
        filter4: dropdownFilterOptions['filter4'],
      })
      setCurrentDefaultAdjustmentNameToUse(value)
    },
    [onChangeFilters, dropdownFilterOptions, setCurrentDefaultAdjustmentNameToUse, clientNameColumnToUse],
  )

  useEffect(() => {
    const fullDataFilteredByClientParent = filterData(
      fullDataForScenario,
      transformIntoFlatStructure({ [CLIENT_PARENT_FILTER_ID]: dropdownFilterOptions[CLIENT_PARENT_FILTER_ID] }),
    )

    let listWithoutDodgyOnes = getListOfClientsFromColumn(fullDataFilteredByClientParent, CLIENT_NAME_COLUMN_IDENTIFIER)
    setClientNameColumnToUse(CLIENT_NAME_COLUMN_IDENTIFIER)
    if (!listWithoutDodgyOnes?.length) {
      listWithoutDodgyOnes = getListOfClientsFromColumn(
        fullDataFilteredByClientParent,
        OLD_CLIENT_NAME_COLUMN_IDENTIFIER,
      )
      setClientNameColumnToUse(OLD_CLIENT_NAME_COLUMN_IDENTIFIER)
    }

    setAvailableClients(listWithoutDodgyOnes)

    if (listWithoutDodgyOnes.length === 1 && !dropdownFilterOptions[CLIENT_NAME_FILTER_ID]?.chosenValue) {
      onChangeClientName(listWithoutDodgyOnes[0])
    }
  }, [fullDataForScenario, dropdownFilterOptions, setAvailableClients, onChangeClientName])

  useEffect(() => {
    const clientParentsAndAssociatedData = groupListOfObjsBy(fullDataForScenario, CLIENT_PARENT_NAME_COLUMN)
    const clientParentNamesAndTotalOriginalGwpAsArray: Array<{ clientName: string; total: number }> = Object.entries(
      clientParentsAndAssociatedData,
    ).map(([clientName, listOfAssociatedData]) => ({
      clientName,
      total: sumListAsIntegers(listOfAssociatedData, PREMIUM_COLUMN_NAME).toNumber(),
    }))

    const sortedListOfNames = sortListBy(clientParentNamesAndTotalOriginalGwpAsArray, ['total'])
      .reverse()
      .map((item) => item.clientName)

    const listWithoutDodgyOnes = removeDodgyValuesFromClientList(sortedListOfNames)

    setAvailableClientParents(listWithoutDodgyOnes)
  }, [fullDataForScenario, setAvailableClients])

  useEffect(() => {
    const allRowsMatchingClient = fullDataForScenario
      .filter((item) => item[clientNameColumnToUse] === currentlyChosenName)
      .map((item) => item[REQUIREMENT_NAME_COLUMN_IDENTIFIER])
    const allRowsUnique = new Set(allRowsMatchingClient)
    const availableRequirements = Array.from(allRowsUnique)
    availableRequirements.sort()
    setAvailableRequirements(availableRequirements)
  }, [currentlyChosenName, fullDataForScenario, clientNameColumnToUse])
  useEffect(() => {
    if (currentlyChosenName && currentlyChosenRequirements?.length) {
      const allLayerReferencesMatchingRequirements = fullDataForScenario
        .filter((item) => item[clientNameColumnToUse] === currentlyChosenName)
        .filter((item) => currentlyChosenRequirements.includes(item[REQUIREMENT_NAME_COLUMN_IDENTIFIER]))
        .map((item) => item[FieldToGroupPortfolioBy.CONVEX_LAYER])
      const allRowsUnique = new Set(allLayerReferencesMatchingRequirements)
      const availableLayerReferences = Array.from(allRowsUnique)
      availableLayerReferences.sort()
      setAvailableLayerRefs(availableLayerReferences)
    }
    //eslint-disable-next-line
  }, [currentlyChosenName, fullDataForScenario, currentlyChosenRequirementsAsStringForRerenderingReaons])
  const onChangeRequirementName = (value: string[]) => {
    if (!value || !value.length) {
      onChangeClientName(currentlyChosenName!)
    } else {
      const newOptions: Record<string, { columnName: string; chosenValue: string[] } | undefined> = {
        ...dropdownFilterOptions,
        [REQUIREMENT_NAME_FILTER_ID]: value.length
          ? {
              columnName: REQUIREMENT_NAME_COLUMN_IDENTIFIER,
              chosenValue: value,
            }
          : undefined,
      }
      delete newOptions[LAYER_REF_FILTER_ID]
      onChangeFilters(newOptions)
    }
  }

  const onChangeLayerName = (value: string[]) => {
    if (!value || !value.length) {
      onChangeRequirementName(currentlyChosenRequirements!)
    } else {
      onChangeFilters({
        ...dropdownFilterOptions,
        [LAYER_REF_FILTER_ID]: value.length
          ? {
              columnName: FieldToGroupPortfolioBy.CONVEX_LAYER,
              chosenValue: value,
            }
          : undefined,
      })
    }
  }

  // TODO same code snippet as ScenarioFilterCard - suggestion to extract it into a hook of its own to reuse in both files
  const updateIndividualFilter =
    (filterId: string) =>
    (columnName: string, chosenValue: FilterType): void => {
      let newDropdownFilterOptions: ManyDropdownFilterValues = { ...dropdownFilterOptions }
      if (!columnName || !chosenValue) {
        delete newDropdownFilterOptions[filterId]
      } else {
        const add = { [filterId]: { columnName, chosenValue } }
        newDropdownFilterOptions = { ...newDropdownFilterOptions, ...add }
      }

      const options = removeLowerFilter(filterId, newDropdownFilterOptions)

      onChangeFilters(options)
    }

  const resetAdjustmentsPage = () => {
    triggerReloadSavedAdjustments()
    onChangeFilters({})
  }

  const availableBusinessTypes = Object.values(RenewalTypes).map((item) => item.toString())

  const onChangeClientParentName = (value: string) => {
    onChangeFilters({
      [CLIENT_PARENT_FILTER_ID]: {
        columnName: CLIENT_PARENT_NAME_COLUMN,
        chosenValue: [value],
      },
      [CLIENT_NAME_FILTER_ID]: dropdownFilterOptions[CLIENT_NAME_FILTER_ID],
      filter4: dropdownFilterOptions['filter4'],
    })
    setCurrentDefaultAdjustmentNameToUse(value)
  }

  if (!currentScenario?.id) {
    return (
      <div
        className="Client"
        id="Client"
      >
        <SelectScenarioMessage />
      </div>
    )
  }

  return (
    <div
      className="Client"
      id="Client"
    >
      <SplitContentContainerWithSideMenu
        leftColumm={
          <FiltersContainer>
            <SectionHeader title="Adjustment Control" />
            {availableBusinessTypes.length && (
              <SegmentedControl
                options={availableBusinessTypes.map((nbt) => ({
                  value: nbt,
                  name: formatEnumForDisplay(nbt),
                }))}
                selectedOption={currentBusinessType ? availableBusinessTypes.indexOf(currentBusinessType) : 0}
                onChange={(item) => setBusinessType(item)}
                className="ScenarioFilterCardSegmentControl"
              />
            )}
            <ColumnAndValueFilter
              name={'filter4'}
              onSelected={updateIndividualFilter('filter4')}
              current={dropdownFilterOptions}
              options={possibleFilterValues}
              hideMultiSelect={false}
              canBeNegated={true}
            />
            {Boolean(availableClientParents.length) && (
              <MultiSelectDropdown
                onSelect={onSelectSingleValueFromMulti(onChangeClientParentName)}
                hasSelectAll
                hasMultipleSelection={false}
                placeholder="Select a client parent"
                selected={
                  dropdownFilterOptions[CLIENT_PARENT_FILTER_ID]?.chosenValue
                    ? [toOption((dropdownFilterOptions[CLIENT_PARENT_FILTER_ID]!.chosenValue as NameListFilter)[0])]
                    : []
                }
                options={availableClientParents.map(toOption)}
                className="ClientParentName"
              />
            )}
            <MultiSelectDropdown
              onSelect={onSelectSingleValueFromMulti(onChangeClientName)}
              hasSelectAll
              hasMultipleSelection={false}
              placeholder="Select a client"
              selected={
                dropdownFilterOptions[CLIENT_NAME_FILTER_ID]?.chosenValue
                  ? [toOption((dropdownFilterOptions[CLIENT_NAME_FILTER_ID]!.chosenValue as NameListFilter)[0])]
                  : []
              }
              options={availableClients.map((clientName) => ({
                label: clientName,
                value: clientName,
              }))}
              className="ClientFilter"
            />
            <MultiSelectDropdown
              onSelect={(options) => onChangeRequirementName(options.map((item) => item.value))}
              hasSelectAll
              hasMultipleSelection={true}
              placeholder="Optionally select a requirement"
              selected={
                dropdownFilterOptions[REQUIREMENT_NAME_FILTER_ID]?.chosenValue
                  ? (dropdownFilterOptions[REQUIREMENT_NAME_FILTER_ID]?.chosenValue as NameListFilter).map(toOption)
                  : []
              }
              options={availableRequirements.map((requirementName) => ({
                label: requirementName,
                value: requirementName,
              }))}
              className="ConvexRequirementName"
              isDisabled={!currentlyChosenName}
            />
            <MultiSelectDropdown
              onSelect={(options) => onChangeLayerName(options.map((item) => item.value))}
              hasSelectAll
              hasMultipleSelection={true}
              placeholder="Optionally select a layer referemce"
              selected={
                dropdownFilterOptions[LAYER_REF_FILTER_ID]?.chosenValue
                  ? (dropdownFilterOptions[LAYER_REF_FILTER_ID]?.chosenValue as string[]).map(toOption)
                  : []
              }
              options={availableLayerRefs.map(toOption)}
              className="ConvexLayerRef"
              isDisabled={!currentlyChosenRequirements?.length}
            />

            <Button
              title="Clear filters"
              secondary={true}
              onClick={resetAdjustmentsPage}
            />
            {graphPanelIsOpen && (
              <TextField
                className="NumberOfClientsToDisplay"
                title="Top N to Display"
                placeholder="0"
                type="number"
                value={numberOfClientsToDisplay || 0}
                onChange={onNumberInputUpdateState(setNumberOfClientsToDisplay)}
              />
            )}
            <div className="CreateAdjustment">
              {currentScenario?.canWriteScenario &&
                currentScenario.status[0].status === ScenarioStatus.IN_PROGRESS &&
                (!currentlyEditingAdjustmentId || currentlyEditingAdjustmentId === PREVIEW_ID) && (
                  <CreateAdjustmentCard
                    key={'create-preview'}
                    adjustmentBeingEditedId={PREVIEW_ID}
                    reloadSavedAdjustmentsAndResetAdjustmentsPage={resetAdjustmentsPage}
                    adjustmentType={'CLIENT_SPECIFIC'}
                    defaultRenewalRetention={100}
                  />
                )}
            </div>
          </FiltersContainer>
        }
        rightColumn={
          graphPanelIsOpen ? (
            <ClientGraphs
              numberOfClientsToDisplay={numberOfClientsToDisplay}
              clientNameColumnToUse={clientNameColumnToUse}
            />
          ) : (
            <Adjustments adjustmentType={'CLIENT_SPECIFIC'} />
          )
        }
        sideMenu={
          <KpiBar>
            <FullKpiDisplay />
          </KpiBar>
        }
      />
    </div>
  )
}
