import { ChangeEventHandler, ReactElement } from 'react'
import { css } from '../../utils/css'
import './WFTextField.scss'
import { Asterisk } from '../asterisk/Asterisk'
import { ignoreNanForNumbers } from '../../utils/ignoreNanForNumbers'

type TextFieldProps = {
  title?: string
  id?: string
  titleHeading?: ReactElement
  percentage?: boolean
  value?: string | number
  placeholder?: string
  pattern?: string
  type?: string
  warning?: string
  error?: string | undefined | null | false
  className?: string | string[]
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  step?: string
  required?: boolean
}

export function TextField(props: TextFieldProps): ReactElement {
  const { percentage, value, title, titleHeading, ...otherProps } = props
  const realValue = ignoreNanForNumbers(value)

  const heading = titleHeading ?? (
    <p className="Title">
      {title} <Asterisk visible={!!props.required} />
    </p>
  )

  return (
    <div className={css('WFTextField', props.className)}>
      {heading}
      <div
        className={title && props.percentage ? 'SuffixWithTitle' : '' || props.percentage ? 'SuffixWithoutTitle' : ''}
      >
        <input
          {...otherProps}
          title={title}
          id={props.id}
          value={realValue}
          className={css('Input', props.warning ? 'Warning' : '', props.error ? 'Error' : '')}
          disabled={props.disabled}
          step={props.step}
        />
      </div>
      {props.warning && <p className="WarningText">{props.warning}</p>}
      {props.error && <p className="ErrorText">{props.error}</p>}
    </div>
  )
}

export default TextField
