import { ChangeEvent, Dispatch, ReactElement, useEffect, useMemo, useReducer } from 'react'
import { Box, Flex, Grid } from '@chakra-ui/react'
import { ExposuresHeader } from './components/ExposuresHeader'
import { createInitialDynamicTableState, dynamicTableReducer } from '../../components/dynamic-table/DynamicTableState'
import {
  ReportMetaData,
  sendReportToSnowflake,
  PostExposureResponse,
  noPendingReportsInTargetMarket,
} from '../../backend/exposures'
import { createInitialState, ExposuresReportReducer } from './components/ExposuresReportState'
import { ExposuresInput } from './components/ExposuresInput'
import { ExposuresReportNameInput } from './components/ExposuresReportNameInput'
import { DynamicTableState } from '../../components/dynamic-table/DynamicTable'
import { Action } from './ExposuresState'
import {
  createErrorHandler,
  createExposuresAlertInitialState,
  exposuresAlertReducer,
} from './components/ExposuresAlert'
import { ExposuresGraphAction, createGraphInitialState, createGraphReducer } from './components/ExposuresGraphState'
import {
  ExposuresGraphAlertAction,
  createGraphAlertInitialState,
  graphAlertReducer,
} from './components/ExposuresGraphAlert'
import {
  ExceedanceGraphData,
  ExceedanceProbabilityAALGraph,
  MarketShareMatrix,
  ReturnPeriod,
} from '../../backend/exposures-V2'
import { ExposuresGraphs } from './ExposuresGraphs'
import { ExposuresReportStatus } from './ReportStatus'
import {
  createExposuresReportOutdatedAlertInitialState,
  exposuresReportOutdatedAlertReducer,
} from './components/ExposuresReportOutdatedAlert'
import { RawEntry } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { isInvalid, dynamicInputInvalid } from '../../components/dynamic-table/DynamicTableInput'
import {
  ExposuresLineGraphMarkersAction,
  createLineGraphMarkersInitialState,
  lineGraphMarkersReducer,
} from './components/ExposuresLineGraphMarkersState'

type Payload = 'new' | 'update'

export type ReportPayload<T extends Payload> = {
  templateId: string
  scenarioId: string
  tmv: string
  name: string
  exceedanceProbabilityChartData: string
  marketShareMatrixChartData: string
  tableData: string
  status: ExposuresReportStatus
  outdated: boolean
  ylt_dvid: number
  asofdate: string
  portfolioData?: T extends 'new' ? RawEntry[] : undefined
  final?: boolean
}

export type ExistingReportPayload = {
  templateId?: string
  scenarioId?: string
  tmv?: string
  name?: string
  exceedanceProbabilityChartData?: string
  marketShareMatrixChartData?: string
  tableData?: string
  status?: ExposuresReportStatus
  outdated?: boolean
  ylt_dvid?: number
  asofdate?: string
  portfolioData?: RawEntry[]
  final?: boolean
}

type ExposureReportProps<T extends Payload> = {
  getTableState: () => Promise<DynamicTableState<number>>
  getReportMetaData: () => Promise<ReportMetaData>
  saveReport: (reportId: string, reportPayload: ReportPayload<T>) => Promise<PostExposureResponse>
  changePage: Dispatch<Action>
  scenarioId: string
  reportId: string
  tmv: string
  getExceedanceGraphData: (
    perils: undefined | string[],
  ) => Promise<[ExceedanceGraphData[], ExceedanceProbabilityAALGraph[]]>
  getMarketShareMatrixGraphData: (perils?: undefined | string[]) => Promise<MarketShareMatrix>
  ylt_dvid: number
  asofdate: string
}

export function ExposureReport<T extends Payload>(props: ExposureReportProps<T>): ReactElement {
  const {
    getTableState,
    getReportMetaData,
    saveReport,
    changePage,
    scenarioId,
    reportId,
    tmv,
    getExceedanceGraphData,
    getMarketShareMatrixGraphData,
    ylt_dvid,
    asofdate,
  } = props

  const [state, dispatch] = useReducer(ExposuresReportReducer, createInitialState())
  const [dynamicTableState, dynamicTableDispatch] = useReducer(dynamicTableReducer, createInitialDynamicTableState())

  const [graphAlertState, graphAlertDispatch] = useReducer(graphAlertReducer, createGraphAlertInitialState())
  const [barGraphState, barGraphDispatch] = useReducer(
    createGraphReducer<MarketShareMatrix>(),
    createGraphInitialState<MarketShareMatrix>({}),
  )
  const [lineGraphState, lineGraphDispatch] = useReducer(
    createGraphReducer<ExceedanceGraphData[]>(),
    createGraphInitialState<ExceedanceGraphData[]>([]),
  )
  const [lineGraphMarkersState, lineGraphMarkersDispatch] = useReducer(
    lineGraphMarkersReducer,
    createLineGraphMarkersInitialState(),
  )

  const [exposuresAlertState, exposuresAlertDispatch] = useReducer(
    exposuresAlertReducer,
    createExposuresAlertInitialState(),
  )
  const errorHandler = createErrorHandler(exposuresAlertDispatch)
  const [exposuresReportOutdatedAlertState, exposuresReportOutdatedAlertDispatch] = useReducer(
    exposuresReportOutdatedAlertReducer,
    createExposuresReportOutdatedAlertInitialState(),
  )

  useEffect(() => {
    ;(async () => {
      try {
        const reportMetaData = await getReportMetaData()
        dispatch({ type: 'setReportName', payload: { reportName: reportMetaData.reportName } })
        dispatch({ type: 'setReportStatus', payload: { reportStatus: reportMetaData.reportStatus } })
        dispatch({ type: 'setReportOutdated', payload: { reportOutdated: reportMetaData.reportOutdated } })
        dispatch({ type: 'setReportFinal', payload: { reportFinal: reportMetaData.reportFinal } })
        if (
          reportMetaData.reportStatus !== ExposuresReportStatus.SCALED_ANALYSIS ||
          reportMetaData.reportOutdated ||
          reportMetaData.reportFinal
        ) {
          dynamicTableDispatch({ type: 'setDisabled', payload: { disabled: true } })
        }
        if (reportMetaData.reportOutdated) {
          exposuresReportOutdatedAlertDispatch({
            type: 'setWarning',
            payload: {
              message: 'Scenario adjustments changed. This report does not reflect the latest adjustment changes.',
            },
          })
        }
      } catch (err: unknown) {
        errorHandler('Failed to get report data', err)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const noPendingReports: boolean = await noPendingReportsInTargetMarket(tmv)
        dispatch({ type: 'setNoPendingReports', payload: { noPendingReports } })
      } catch (err: unknown) {
        errorHandler('Failed to get pending reports in target market', err)
      }
    })()
  }, [])

  useEffect(() => {
    if (state.reportStatus === ExposuresReportStatus.ANALYSIS_IN_PROGRESS) {
      exposuresAlertDispatch({
        type: 'setWarning',
        payload: { message: 'Analysis in progress! Once projection is created results will be updated to reflect it.' },
      })
    } else if (state.reportStatus === ExposuresReportStatus.ANALYSIS_COMPLETE) {
      exposuresAlertDispatch({
        type: 'setSuccess',
        payload: { message: 'Analysis has been completed. The graphs below now show projected results.' },
      })
    }
  }, [state.reportStatus])

  const handleSave = async () => {
    const reportPayload: ReportPayload<T> = {
      templateId: '',
      scenarioId: scenarioId,
      tmv: tmv,
      name: state.reportName,
      exceedanceProbabilityChartData: JSON.stringify([lineGraphState.data, lineGraphMarkersState.data]),
      marketShareMatrixChartData: JSON.stringify(barGraphState.data),
      tableData: JSON.stringify(dynamicTableState.table),
      status: state.reportStatus,
      outdated: state.reportOutdated,
      ylt_dvid,
      asofdate,
    }

    try {
      const noInvalidInput = dynamicTableState.table.cells.every((cell) => !isInvalid(cell))
      const maxLossesInvalid = dynamicInputInvalid(dynamicTableState.table.cells)

      if (!noInvalidInput || maxLossesInvalid.invalid) {
        exposuresAlertDispatch({
          type: 'setError',
          payload: {
            message: 'Input validation failed, unable to save report. Please check the values again.',
            error: 'Input validation failed',
          },
        })
        return
      }
      const res = await saveReport(reportId, reportPayload)
      changePage({ type: 'toReport', payload: { reportId: res.id } })
      exposuresAlertDispatch({ type: 'setSuccess', payload: { message: 'Sucessfully saved exposures data' } })
    } catch (err: unknown) {
      errorHandler('Failed to save report', err)
      let errorMessage = ''
      if (typeof err === 'string') {
        errorMessage = err
      }

      if (err instanceof Error) {
        errorMessage = err.message
      }

      exposuresAlertDispatch({
        type: 'setError',
        payload: { message: 'Failed to save exposures data', error: errorMessage },
      })
    }
  }

  const handleReprojection = async () => {
    try {
      sendReportToSnowflake(reportId)
      dispatch({ type: 'setReportStatus', payload: { reportStatus: ExposuresReportStatus.ANALYSIS_IN_PROGRESS } })
      exposuresAlertDispatch({
        type: 'setWarning',
        payload: { message: 'Analysis in progress! Once projection is created results will be updated to reflect it.' },
      })
      dynamicTableDispatch({ type: 'setDisabled', payload: { disabled: true } })
    } catch (err: unknown) {
      let errorMessage = ''
      if (typeof err === 'string') {
        errorMessage = err
      }

      if (err instanceof Error) {
        errorMessage = err.message
      }

      exposuresAlertDispatch({
        type: 'setError',
        payload: { message: 'Failed to send report for reprojection', error: errorMessage },
      })
    }
  }

  const onCloseAlert = () => {
    exposuresAlertDispatch({ type: 'closeAlert' })
  }

  const handleReportNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const reportName = e.target.value
    dispatch({ type: 'setReportName', payload: { reportName } })
  }

  const toReportsList = () => {
    changePage({ type: 'toList' })
  }

  const dispatchInputUpdate = (data: DynamicTableState<number>) => {
    const cellStateUpdate = (id: number, value: number) => {
      dynamicTableDispatch({ type: 'updateCell', payload: { id, value } })
    }

    const cellScalerUpdate = (id: number, scaleFactor: number, rowName: string, columnPosition: number) => {
      dynamicTableDispatch({ type: 'updateScaler', payload: { id, scaleFactor, rowName, columnPosition } })
    }

    const depthSelector = (id: number, depthKey: string) => {
      dynamicTableDispatch({ type: 'updateColumnOffset', payload: { id, depthKey } })
    }

    dynamicTableDispatch({ type: 'initialiseTable', payload: { tableState: data } })
    dynamicTableDispatch({ type: 'applyUpdateFunction', payload: { updateFunction: cellStateUpdate } })
    dynamicTableDispatch({ type: 'applyScalerUpdate', payload: { scalerUpdateFunction: cellScalerUpdate } })
    dynamicTableDispatch({ type: 'applyChangeDepthSelector', payload: { depthSelectorFunction: depthSelector } })

    dispatch({
      type: 'setSelectedPeril',
      payload: {
        selectedPeril: data.columns[0],
      },
    })

    getExceedanceGraph(data.columns, lineGraphDispatch, lineGraphMarkersDispatch, graphAlertDispatch)
    getMarketShareMatrixGraph(data.columns, barGraphDispatch, graphAlertDispatch)
  }

  const getExceedanceGraph = async (
    perils: string[],
    graphDispatch: React.Dispatch<ExposuresGraphAction<ExceedanceGraphData[]>>,
    graphMarkersDispatch: React.Dispatch<ExposuresLineGraphMarkersAction>,
    graphAlertDispatch: React.Dispatch<ExposuresGraphAlertAction>,
  ) => {
    try {
      const [graphData, aalData] = await getExceedanceGraphData(perils)
      graphDispatch({
        type: 'setLoaded',
        payload: { data: graphData },
      })
      graphMarkersDispatch({ type: 'setLoaded', payload: { data: aalData } })
    } catch (err: unknown) {
      let errorMessage = ''
      if (typeof err === 'string') {
        errorMessage = err
      }
      if (err instanceof Error) {
        errorMessage = err.message
      }
      graphDispatch({ type: 'setError', payload: { error: errorMessage } })
      graphAlertDispatch({
        type: 'setError',
        payload: {
          message: 'Something went wrong. Please refresh the page or try again later.',
          error: 'An error occurred while loading the bar graph',
        },
      })
    }
  }

  const getMarketShareMatrixGraph = async (
    perils: string[],
    graphDispatch: React.Dispatch<ExposuresGraphAction<MarketShareMatrix>>,
    graphAlertDispatch: React.Dispatch<ExposuresGraphAlertAction>,
  ) => {
    try {
      const graphData = await getMarketShareMatrixGraphData(perils)
      graphDispatch({
        type: 'setLoaded',
        payload: { data: graphData },
      })
    } catch (err: unknown) {
      let errorMessage = ''
      if (typeof err === 'string') {
        errorMessage = err
      }
      if (err instanceof Error) {
        errorMessage = err.message
      }
      graphDispatch({ type: 'setError', payload: { error: errorMessage } })
      graphAlertDispatch({
        type: 'setError',
        payload: {
          message: 'Something went wrong. Please refresh the page or try again later.',
          error: 'An error occurred while loading the bar graph',
        },
      })
    }
  }

  const returnPeriod = useMemo(() => {
    const peril = state.selectedPeril
    const perilPosition = dynamicTableState.table.columns.indexOf(peril)
    if (perilPosition === -1) {
      return 'OEP'
    }

    const returnType = dynamicTableState.table.selector[perilPosition].currentDepth
    return returnType.split(' ')[1] as ReturnPeriod
  }, [state.selectedPeril, dynamicTableState.table.selector])

  return (
    <Box height={'100%'}>
      <ExposuresHeader
        handleSave={handleSave}
        handleReprojection={handleReprojection}
        alertStatus={exposuresAlertState}
        onCloseAlert={onCloseAlert}
        toReportsList={toReportsList}
        reportStatus={state.reportStatus}
        targetMarketHasNoPendingReports={state.noPendingReports}
        reportId={reportId}
        reportOutdatedAlertStatus={exposuresReportOutdatedAlertState}
      />
      <Grid
        templateColumns={'2fr 1fr'}
        pl={'3rem'}
        height={'100%'}
      >
        <Flex flexDirection={'column'}>
          <ExposuresReportNameInput
            onChange={handleReportNameChange}
            reportName={state.reportName}
            isDisabled={dynamicTableState.table.disabled}
          />
          <ExposuresInput
            dynamicTableState={dynamicTableState}
            dispatchFunction={dispatchInputUpdate}
            fetch={getTableState}
            disabled={dynamicTableState.table.disabled}
            asOfDate={asofdate}
          />
        </Flex>
        <Flex
          borderLeft={'1px solid rgba(0,0,0,0.1)'}
          height={'100%'}
          flexDirection={'column'}
        >
          <ExposuresGraphs
            graphAlertState={graphAlertState}
            returnPeriod={returnPeriod}
            dispatch={dispatch}
            columns={dynamicTableState.table.columns}
            barGraphState={barGraphState}
            lineGraphState={lineGraphState}
            lineGraphMarkers={lineGraphMarkersState.data}
            state={state}
            overrideScaled={scalePoints(
              lineGraphState.data.find((d) => {
                return d.id === 'Scaled'
              }),
              getScaleFactor(
                dynamicTableState.table.scaler['Override Scaled'],
                state.selectedPeril,
                dynamicTableState.table.columns,
              ),
            )}
          />
        </Flex>
      </Grid>
    </Box>
  )
}

function getScaleFactor(scaler: number[], peril: string, columns: string[]): number {
  const colPosition = columns.indexOf(peril)
  if (colPosition === -1) {
    return 1
  }

  return scaler[colPosition]
}

function scalePoints(line: ExceedanceGraphData | undefined, scaleFactor: number): ExceedanceGraphData {
  const output: ExceedanceGraphData = {
    id: 'Override Scaled',
    color: '#FF00FF',
    data: {},
  }

  if (line === undefined) {
    return output
  }

  const perils = Object.keys(line.data)

  // transform all of the points in a given map
  for (let i = 0; i < perils.length; i++) {
    output.data[perils[i]] = {
      OEP: [],
      AEP: [],
    }

    output.data[perils[i]].OEP = line.data[perils[i]].OEP.map((d) => {
      return { x: d.x, y: d.y * scaleFactor }
    })
    output.data[perils[i]].AEP = line.data[perils[i]].AEP.map((d) => {
      return { x: d.x, y: d.y * scaleFactor }
    })
  }

  return output
}
