import React, { useContext, useEffect, useMemo, useState } from 'react'
import AutosizedGrid from './AutosizedGrid'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { filterOutColumnsWeDontWant } from '../../utils/outputs/outputFilter'
import {
  ADJUSTED_CONVEX_LIMIT,
  ADJUSTED_CONVEX_SHARE_VALUE,
  ADJUSTED_PREMIUM_VALUE,
  CONVEX_LIMIT_COLUMN_NAME,
  PREMIUM_COLUMN_NAME,
} from '../../backend/calculate-kpis'
import { formatForDisplay, formatPercentageToFixedDecimalPoint, ONE_HUNDRED } from '../../utils/numbers'
import { CONVEX_SHARE_COLUMN_NAME } from '../../backend/calculate-possible-filter-values'
import { sortListBy } from '../../utils/lists'

interface PortfolioDataTableProps {
  includedColumns?: string[]
  heightToSubtract?: number
}

type Filters = { [filterColumn: string]: string | undefined }

const ROWS_THAT_ARE_DOLLAR_VALUES = [
  PREMIUM_COLUMN_NAME,
  ADJUSTED_PREMIUM_VALUE,
  CONVEX_LIMIT_COLUMN_NAME,
  ADJUSTED_CONVEX_LIMIT,
]
const ROWS_THAT_ARE_PERCENTAGES = [CONVEX_SHARE_COLUMN_NAME, ADJUSTED_CONVEX_SHARE_VALUE, 'Written Line', 'Signed Line']
export const WayfinderInputFieldsToAppendToEnd = {
  ORIG_TO_BASE_EXCHANGE_RATE: 'Orig to Base Exchange Rate',
  DIGITAL_CHANNEL: 'Digital Channel',
}

function transformEntryForDisplay(individualDataFieldFromRow: [string, any]): [string, any] {
  const columnName = individualDataFieldFromRow[0]
  const value = individualDataFieldFromRow[1]

  if (ROWS_THAT_ARE_DOLLAR_VALUES.includes(columnName)) {
    try {
      return [columnName, formatForDisplay(value)]
    } catch (e) {
      return individualDataFieldFromRow
    }
  }

  if (ROWS_THAT_ARE_PERCENTAGES.includes(columnName)) {
    try {
      return [columnName, formatPercentageToFixedDecimalPoint(ONE_HUNDRED.mul(value), 4)]
    } catch (e) {
      return individualDataFieldFromRow
    }
  }

  return individualDataFieldFromRow
}

function getReadyForDisplay(row: any) {
  const allEntriesInRow = Object.entries(row)

  const transformedEntries = allEntriesInRow.map(transformEntryForDisplay)

  return transformedEntries.reduce((a, b) => {
    return {
      ...a,
      [b[0]]: b[1],
    }
  }, {})
}

export function appendToEndOfArray(inputArr: string[], keys: string[]): string[] {
  let reorderedArr = Array.from(new Set(inputArr))
  keys.forEach((currentKey) => {
    const keyIndex = reorderedArr.indexOf(currentKey)
    if (keyIndex !== -1) {
      reorderedArr.splice(keyIndex, 1)
      reorderedArr.push(currentKey)
    }
  })
  return reorderedArr
}

export function appendColumnsToEnd<T extends { [key: string]: unknown }, K extends keyof T>(data: T[], keys: K[]): T[] {
  const reorderedData = data.map((obj) => {
    let dataKeys = Array.from(Object.keys(obj))
    const orderedDataKeys = appendToEndOfArray(
      dataKeys,
      keys.map((key) => key.toString()),
    )
    return orderedDataKeys.reduce(function (result, key) {
      return {
        ...result,
        [key]: obj[key as K],
      }
    }, {} as Partial<T>)
  })
  return reorderedData as T[]
}

function PortfolioDataTable(props: PortfolioDataTableProps) {
  const [currentFilters, setFilters] = useState<Filters>({})
  const [filteredRows, setFilteredData] = useState<any[]>([])
  const [filteredAndUpdatedForDisplayRows, setFilteredAndUpdatedForDisplayRows] = useState<any[]>([])
  const [currentColumnToSortBy, setCurrentColumnToSortBy] = useState<string>('')
  const [sortDirection, setSortDirection] = useState(false)
  const { filteredDataForScenario } = useContext(FullScenarioDataContext)

  const allFields = useMemo(() => {
    const filteredDataColumnsRemoved = filterOutColumnsWeDontWant(filteredDataForScenario)
    const allKeysWithDuplicates = filteredDataColumnsRemoved.flatMap(Object.keys)
    const allKeys = Array.from(new Set(allKeysWithDuplicates))
    const orderedKeys = appendToEndOfArray(allKeys, [
      WayfinderInputFieldsToAppendToEnd.DIGITAL_CHANNEL,
      WayfinderInputFieldsToAppendToEnd.ORIG_TO_BASE_EXCHANGE_RATE,
    ])
    return orderedKeys
  }, [filteredDataForScenario])

  const filteredFieldsToUse = props.includedColumns ?? allFields

  useEffect(() => {
    const filteredRows: any[] = Object.entries(currentFilters).reduce((dataSoFar, [columnName, value]) => {
      if (!value) {
        return dataSoFar
      }

      return dataSoFar.filter((currentDataItem: any) =>
        typeof currentDataItem[columnName] === 'string'
          ? currentDataItem[columnName].toLowerCase().includes(value.toLowerCase())
          : false,
      )
    }, filteredDataForScenario)

    setFilteredData(filteredRows)
  }, [filteredDataForScenario, currentFilters])

  useEffect(() => {
    const updatedRows = filteredRows.map(getReadyForDisplay)
    setFilteredAndUpdatedForDisplayRows(updatedRows)
  }, [filteredRows])

  const filteredAndUpdatedAndSortedRows = useMemo(() => {
    if (!currentColumnToSortBy) {
      return filteredAndUpdatedForDisplayRows
    } else {
      const sorted = sortListBy(filteredAndUpdatedForDisplayRows, [currentColumnToSortBy])
      if (sortDirection) {
        sorted.reverse()
      }
      return sorted
    }
  }, [sortDirection, filteredAndUpdatedForDisplayRows, currentColumnToSortBy])

  return (
    <AutosizedGrid
      onUpdateFilter={(columnName) => (event) => setFilters({ ...currentFilters, [columnName]: event.target.value })}
      columns={filteredFieldsToUse}
      onUpdateSortOrder={(columName) => {
        setCurrentColumnToSortBy(columName)
        setSortDirection(!sortDirection)
      }}
      data={filteredAndUpdatedAndSortedRows}
      heightToSubtractFromFull={props.heightToSubtract || 80}
    />
  )
}

export default PortfolioDataTable
