import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Big from 'big.js'

import { RenewalAdjustmentsProvider } from '../RenewalAdjustmentsProvider'
import { PseudoPortfolioProvider } from '../NewPseudoPortfolioItemsProvider'
import { calculateWhichActualDataIsARenewal, calculateWhichActualDataIsNew } from '../../backend/calculate-with-actuals'
import {
  calculateGrarcKpi,
  calculateKPIs,
  calculateWeightedAverageKpis,
  filterData,
  ScenarioKeyPerformanceIndicators,
  WeightedAvgKpis,
} from '../../backend/calculate-kpis'
import { MarketDataContextV2, MarketDataProviderV2 } from '../MarketDataProviderV2'
import { NewAdjustment } from '../../../../backend/src/modules/new-adjustments/domain/NewAdjustment.types'
import { Adjustment } from '../../backend/adjustments'
import { NewClient } from '../../backend/new-clients'
import { FilterValues } from '../../backend/calculate-possible-filter-values'
import { filterByDataType, WayfinderDataType } from '../../backend/TypeOfData'
import { fetchFullScenarioData, FullScenarioDataRequest } from '../../backend/scenarios-data'
import { transformIntoFlatStructure } from '../FilterProvider'
import { NewClientItemsProvider } from '../NewClientItemsProvider'
import { ScenarioContext } from '../ScenarioProvider'
import { ActualsContext } from '../ActualDataChoiceProvider'
import { ProgressContext, ProgressState } from '../ProgressProvider'
import { numericValueIsDefined, ONE_HUNDRED } from '../../utils/numbers'
import { useDataTypesInGraphs } from './useDataTypesInGraphs'
import { FilterContext } from '../FilterProvider'

export type RawEntry = { [column: string]: string }

export interface FullScenarioDataContainer {
  fullDataForScenario: RawEntry[]
  fullRenewalKpis: ScenarioKeyPerformanceIndicators | undefined
  fullNewKpis: ScenarioKeyPerformanceIndicators | undefined
  fullWeightedAverageKpis: WeightedAvgKpis | undefined
  grarcKpi: Big | undefined
  newGrarcKpi: Big | undefined
  renewalGrarcKpi: Big | undefined
  weightedAverageRenewalKpis: WeightedAvgKpis | undefined
  filteredDataForScenario: any[]
  filteredRenewalDataForScenario: any[]
  filteredNewDataForScenario: any[]
  filteredNewClientDataForScenario: any[]
  filteredNewCustomPortfolioDataForScenario: any[]
  filteredRenewalKpis: ScenarioKeyPerformanceIndicators | undefined
  filteredNewKpis: ScenarioKeyPerformanceIndicators | undefined
  filteredDataToUseInGraph: any[]
  reloadData: () => void
  setPreviewRenewal: (adj: Adjustment | undefined | null) => void
  setPreviewNewVirtual: (adj: NewAdjustment | undefined | null) => void
  setPreviewNewClient: (adj: NewClient | undefined | null) => void
}

export const initialFullScenarioDataContextValues: FullScenarioDataContainer = {
  fullDataForScenario: [],
  fullRenewalKpis: undefined,
  fullNewKpis: undefined,
  fullWeightedAverageKpis: undefined,
  grarcKpi: undefined,
  newGrarcKpi: undefined,
  renewalGrarcKpi: undefined,
  weightedAverageRenewalKpis: undefined,
  filteredDataForScenario: [],
  filteredRenewalDataForScenario: [],
  filteredNewDataForScenario: [],
  filteredRenewalKpis: undefined,
  filteredNewKpis: undefined,
  filteredDataToUseInGraph: [],
  filteredNewClientDataForScenario: [],
  filteredNewCustomPortfolioDataForScenario: [],
  reloadData: () => undefined,
  setPreviewNewClient: () => undefined,
  setPreviewRenewal: () => undefined,
  setPreviewNewVirtual: () => undefined,
}

export const FullScenarioDataContext = createContext<FullScenarioDataContainer>(initialFullScenarioDataContextValues)

export function splitIntoRenewalAndNew(data: any[] | undefined): RenewalAndNew {
  if (!data) {
    return { new: [], newClient: [], renewal: [], newCustomPortfolio: [] }
  }
  const renewalData = filterByDataType(data, [
    WayfinderDataType.RENEWAL_ADJUSTED,
    WayfinderDataType.RENEWAL_UNADJUSTED,
    WayfinderDataType.RENEWAL_CLIENT,
    WayfinderDataType.LEGACY_RENEWAL_CLIENT,
  ])
  const newData = filterByDataType(data, [WayfinderDataType.NEW])
  const newClientData = filterByDataType(data, [WayfinderDataType.NEW_CLIENT])
  const newCustomPortfolioData = filterByDataType(data, [WayfinderDataType.NEW_CUSTOM_PORTFOLIO])
  const allActuals = filterByDataType(data, [WayfinderDataType.ACTUALS])

  const actualsThatRelateToRenewals = calculateWhichActualDataIsARenewal(allActuals)
  const actualsThatRelateToNew = calculateWhichActualDataIsNew(allActuals)

  const allRenewalData = [...renewalData, ...actualsThatRelateToRenewals]
  const allNewData = [...newData, ...actualsThatRelateToNew]

  return {
    renewal: allRenewalData,
    new: allNewData,
    newClient: newClientData,
    newCustomPortfolio: newCustomPortfolioData,
  }
}

type FilteredFullData =
  | RenewalAndNew['renewal']
  | RenewalAndNew['new']
  | RenewalAndNew['newClient']
  | RenewalAndNew['newCustomPortfolio']

type RenewalAndNew = {
  renewal: any[]
  new: any[]
  newClient: any[]
  newCustomPortfolio: any[]
}

const FullScenarioDataProviderTransformer = (props: PropsWithChildren): JSX.Element => {
  const { currentScenario } = useContext(ScenarioContext)
  const { marketData } = useContext(MarketDataContextV2)
  const { dropdownFilterOptions } = useContext(FilterContext)
  const { updateIndividualProgressItem } = useContext(ProgressContext)
  const { actualsQuery } = useContext(ActualsContext)

  const [fullDataWithActuals, setFullDataWithActuals] = useState<any[]>([])
  const [previewRenewal, setPreviewRenewal] = useState<Adjustment | undefined | null>(undefined)
  const [previewNewVirtual, setPreviewNewVirtual] = useState<NewAdjustment | undefined | null>(undefined)
  const [previewNewClient, setPreviewNewClient] = useState<NewClient | undefined | null>(undefined)

  const [actualFromQuery] = actualsQuery

  const dataTypesToUseInGraphs = useDataTypesInGraphs()

  const reloadData: () => Promise<void> = useCallback(async () => {
    if (!currentScenario) {
      return
    }
    updateIndividualProgressItem('fetchingFullScenarioData', ProgressState.LOADING)

    const options: FullScenarioDataRequest = {
      previewNewClient,
      previewNewVirtual,
      previewRenewal,
      actualsNameToUse: actualFromQuery,
      dataSource: currentScenario.data,
    }

    try {
      const data = await fetchFullScenarioData(currentScenario.id, options)
      updateIndividualProgressItem('fetchingFullScenarioData', ProgressState.FINISHED)
      setFullDataWithActuals(data)
    } catch (_) {
      updateIndividualProgressItem('fetchingFullScenarioData', ProgressState.ERROR)
    }
  }, [
    currentScenario?.id,
    currentScenario?.data,
    previewRenewal,
    previewNewClient,
    previewNewVirtual,
    actualFromQuery,
    updateIndividualProgressItem,
  ])

  useEffect(() => {
    reloadData()
  }, [reloadData])

  const filtersToApply: FilterValues = useMemo(
    () => transformIntoFlatStructure(dropdownFilterOptions),
    [dropdownFilterOptions],
  )

  const fullRenewalAndNew: RenewalAndNew = useMemo(
    () => splitIntoRenewalAndNew(fullDataWithActuals),
    [fullDataWithActuals],
  )

  const fullRenewalKpis: ScenarioKeyPerformanceIndicators = useMemo(
    () => calculateKPIs(fullRenewalAndNew.renewal),
    [fullRenewalAndNew],
  )

  const fullNewKpis: ScenarioKeyPerformanceIndicators = useMemo(
    () =>
      calculateKPIs([
        ...fullRenewalAndNew.new,
        ...fullRenewalAndNew.newClient,
        ...fullRenewalAndNew.newCustomPortfolio,
      ]),
    [fullRenewalAndNew.new, fullRenewalAndNew.newClient, fullRenewalAndNew.newCustomPortfolio],
  )

  const filteredDataForScenario = useMemo(
    () => filterData(fullDataWithActuals, filtersToApply),
    [fullDataWithActuals, filtersToApply],
  )

  const filteredRenewalAndNew: RenewalAndNew = useMemo(
    () => splitIntoRenewalAndNew(filteredDataForScenario),
    [filteredDataForScenario],
  )

  const filteredFullData: FilteredFullData = useMemo(
    () => [
      ...filteredRenewalAndNew.renewal,
      ...filteredRenewalAndNew.new,
      ...filteredRenewalAndNew.newClient,
      ...filteredRenewalAndNew.newCustomPortfolio,
    ],
    [filteredRenewalAndNew],
  )

  const filteredDataToUseInGraph: any[] = useMemo(() => {
    let filteredDataToReturn: FilteredFullData = filteredFullData
    if (dataTypesToUseInGraphs?.length) {
      filteredDataToReturn = filterByDataType(filteredDataToReturn, dataTypesToUseInGraphs)
    }
    return filteredDataToReturn
  }, [filteredFullData, dataTypesToUseInGraphs])

  const filteredRenewalKpis: ScenarioKeyPerformanceIndicators = useMemo(
    () => calculateKPIs(filteredRenewalAndNew.renewal),
    [filteredRenewalAndNew],
  )

  const filteredNewKpis: ScenarioKeyPerformanceIndicators = useMemo(
    () =>
      calculateKPIs([
        ...filteredRenewalAndNew.new,
        ...filteredRenewalAndNew.newClient,
        ...filteredRenewalAndNew.newCustomPortfolio,
      ]),
    [filteredRenewalAndNew.new, filteredRenewalAndNew.newClient, filteredRenewalAndNew.newCustomPortfolio],
  )

  const grarcKpi: undefined | Big = useMemo(() => calculateGrarcKpi(fullDataWithActuals), [fullDataWithActuals])

  const newGrarcKpi: undefined | Big = useMemo(
    () =>
      calculateGrarcKpi([
        ...filteredRenewalAndNew.new,
        ...filteredRenewalAndNew.newClient,
        ...filteredRenewalAndNew.newCustomPortfolio,
      ]),
    [filteredRenewalAndNew.new, filteredRenewalAndNew.newClient, filteredRenewalAndNew.newCustomPortfolio],
  )
  const renewalGrarcKpi: undefined | Big = useMemo(
    () => calculateGrarcKpi([...filteredRenewalAndNew.renewal]),
    [filteredRenewalAndNew.renewal],
  )

  const fallbackAcqValue: Record<string, Big> = useMemo(() => {
    if (currentScenario && numericValueIsDefined(marketData?.convexAcquisitionRatio)) {
      return { [currentScenario.market]: new Big(marketData?.convexAcquisitionRatio!).div(ONE_HUNDRED) }
    }
    return {}
  }, [currentScenario, marketData])

  const fullWeightedAverageKpis: WeightedAvgKpis = useMemo(
    () => calculateWeightedAverageKpis(fullDataWithActuals, fallbackAcqValue),
    [fullDataWithActuals, fallbackAcqValue],
  )

  const weightedAverageRenewalKpis: WeightedAvgKpis = useMemo(
    () => calculateWeightedAverageKpis(fullRenewalAndNew.renewal, fallbackAcqValue),
    [fullRenewalAndNew, fallbackAcqValue],
  )

  return (
    <FullScenarioDataContext.Provider
      value={{
        fullDataForScenario: fullDataWithActuals,
        fullRenewalKpis,
        fullNewKpis,
        fullWeightedAverageKpis,
        grarcKpi,
        newGrarcKpi,
        renewalGrarcKpi,
        weightedAverageRenewalKpis,
        filteredDataForScenario,
        filteredRenewalKpis,
        filteredNewKpis,
        filteredNewDataForScenario: filteredRenewalAndNew.new,
        filteredRenewalDataForScenario: filteredRenewalAndNew.renewal,
        filteredNewClientDataForScenario: filteredRenewalAndNew.newClient,
        filteredNewCustomPortfolioDataForScenario: filteredRenewalAndNew.newCustomPortfolio,
        filteredDataToUseInGraph,
        reloadData,
        setPreviewNewVirtual,
        setPreviewNewClient,
        setPreviewRenewal,
      }}
    >
      {props.children}
    </FullScenarioDataContext.Provider>
  )
}

export const FullScenarioDataProvider = (props: PropsWithChildren) => {
  return (
    <MarketDataProviderV2>
      <FullScenarioDataProviderTransformer>
        <RenewalAdjustmentsProvider>
          <PseudoPortfolioProvider>
            <NewClientItemsProvider>{props.children}</NewClientItemsProvider>
          </PseudoPortfolioProvider>
        </RenewalAdjustmentsProvider>
      </FullScenarioDataProviderTransformer>
    </MarketDataProviderV2>
  )
}
