import { NCPSegments } from '../../backend/new-custom-portfolio-items'

export function calculateTotals(segmentData: NCPSegments[], totalName: keyof Omit<NCPSegments, 'name'>): number {
  return segmentData.reduce((total, segment) => {
    const { name, ...updatedSegmentData } = segment
    return total + (Number.isNaN(updatedSegmentData[totalName]) ? 0 : updatedSegmentData[totalName])
  }, 0)
}

export function calculateTotalPercentages(totalPercent: number[], gwp: number[]): number {
  if (totalPercent.length !== gwp.length) {
    return 0
  }
  let sumTotalPercent = 0
  let sumGwp = 0
  for (let i = 0; i < totalPercent.length; i++) {
    const safeIthGwp = Number.isNaN(gwp[i]) ? 0 : gwp[i]
    sumTotalPercent += totalPercent[i] * safeIthGwp
    sumGwp += safeIthGwp
  }
  if (sumGwp === 0) {
    return 0
  }

  return parseFloat((sumTotalPercent / sumGwp).toFixed(4))
}
