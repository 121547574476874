import Modal from 'react-modal'
import React, { MouseEventHandler, useContext, useEffect, useMemo, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Card } from '../../components/card/Card'
import { useFlags } from 'launchdarkly-react-client-sdk'

import './ScenarioModal.scss'

import Button from '../../components/button/Button'
import TextField from '../../components/text-field/WFTextField'
import { onInputUpdateState } from '../../utils/onChange'
import { duplicateScenario, fetchScenario, Scenario } from '../../backend/scenarios'
import {
  makeAllSourceOptions,
  previousYears,
  validateNameAndCalculateIfErrorIsNeeded,
} from '../../components/create-scenario-card/CreateScenarioCard'
import { MultiSelectDropdown } from '../../components/multi-select-dropdown/MultiSelectDropdown'
import { DropdownGroup, toOption } from '../../utils/lists'
import { onSelectSingleValueFromMulti } from './ScenarioChooser'
import { PortfolioListingContext } from '../../providers/PortfolioListingProvider'
import { DataChoice } from '../../../../backend/src/modules/portfolios/domain/PortfolioListing.types'
import { ActualDropdown, hideSnowflakeOnEnv } from '../../components/actual-dropdown/ActualDropdown'
import { Flag } from '../exposures/StackedExposures'

export const DUPLICATING_SCENARIO_MODAL_QUERY_ID = 'currentlyDuplicatingId'

interface FinishDuplicatingScenarioModalProps {
  currentScenarios: Scenario[]
  reloadScenarios: () => void
}

export function FinishDuplicatingScenarioModal(props: FinishDuplicatingScenarioModalProps) {
  const { snowflake } = useFlags<Flag>()

  const [currentlyDuplicatingScenarioId, setCurrentlyDuplicatingScenarioId] = useQueryParam(
    DUPLICATING_SCENARIO_MODAL_QUERY_ID,
    StringParam,
  )

  const { portfolioStatus } = useContext(PortfolioListingContext)

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [currentlyEnteredScenarioName, setCurrentlyEnteredScenarioName] = useState<string>('')
  const [currentlyEnteredActuals, setCurrentlyEnteredActuals] = useState<string | undefined | null>('')
  const [currentlyEnteredDataName, setCurrentlyEnteredDataName] = useState<string | undefined | null>('')
  const [currentScenario, setCurrentScenario] = useState<Scenario | undefined>()

  const possibleDataItems: DataChoice[] = useMemo(() => {
    if (!currentScenario) {
      return []
    }
    const { year, division, team, market } = currentScenario
    return previousYears({ year, division, team, market }, portfolioStatus)
  }, [portfolioStatus, currentScenario])
  const groupedOptionsForData: DropdownGroup[] = useMemo(
    () => makeAllSourceOptions(possibleDataItems),
    [possibleDataItems],
  )

  const chosenDataIsFromPreviousScenario: undefined | boolean = useMemo(() => {
    const currentDataItem = possibleDataItems.find((item) => item.name === currentlyEnteredDataName)

    return currentDataItem?.isScenarioOutput
  }, [possibleDataItems, currentlyEnteredDataName])

  const currentDataScenarioId: undefined | string = useMemo(() => {
    const currentDataItem = possibleDataItems.find((item) => item.name === currentlyEnteredDataName)

    return currentDataItem?.scenarioId
  }, [possibleDataItems, currentlyEnteredDataName])

  useEffect(() => {
    if (!currentlyDuplicatingScenarioId) {
      return
    }
    fetchScenario(currentlyDuplicatingScenarioId).then(setCurrentScenario).catch(cancel)
    //eslint-disable-next-line
  }, [currentlyDuplicatingScenarioId])

  useEffect(() => {
    if (!currentScenario) {
      return
    }

    setCurrentlyEnteredScenarioName(currentScenario.name + ' (Copy)')
    setCurrentlyEnteredActuals(currentScenario.previousYearActualsDataName)
  }, [currentScenario])

  const cancel = () => {
    setCurrentlyDuplicatingScenarioId(undefined)
    setShouldValidate(false)
    setCurrentlyEnteredScenarioName('')
    setCurrentlyEnteredActuals('')
    setCurrentScenario(undefined)
  }

  const save: MouseEventHandler = async (): Promise<void> => {
    setShouldValidate(true)
    if (!currentScenario) {
      return
    }
    if (validateNameAndCalculateIfErrorIsNeeded(currentlyEnteredScenarioName, props.currentScenarios)) {
      return
    }
    await duplicateScenario(currentScenario.id, {
      overrideName: currentlyEnteredScenarioName || currentScenario.name,
      overrideActuals: chosenDataIsFromPreviousScenario
        ? currentlyEnteredActuals || currentScenario.previousYearActualsDataName
        : undefined,
      overrideDataName: currentlyEnteredDataName || currentScenario.data,
      overrideChainedScenarioId: chosenDataIsFromPreviousScenario
        ? currentDataScenarioId || currentScenario.chainedScenarioId
        : undefined,
    })
    props.reloadScenarios()
    cancel()
  }

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={
        Boolean(currentlyDuplicatingScenarioId) &&
        props.currentScenarios.find((scenario) => scenario.id === currentlyDuplicatingScenarioId) !== undefined
      }
      className="ScenarioModal"
      overlayClassName="ModalOverlay"
    >
      <Card>
        <h3 className="ScenarioModalTitle">{`Duplicate Scenario`}</h3>
        <TextField
          className="ScenarioName"
          title="Name"
          value={currentlyEnteredScenarioName}
          onChange={onInputUpdateState(setCurrentlyEnteredScenarioName)}
          error={
            shouldValidate &&
            validateNameAndCalculateIfErrorIsNeeded(currentlyEnteredScenarioName, props.currentScenarios)
          }
        />
        <MultiSelectDropdown
          title="Source Data"
          placeholder="Choose..."
          className="Data"
          onSelect={(chosenOption) => {
            onSelectSingleValueFromMulti(setCurrentlyEnteredDataName)(chosenOption)
            setCurrentlyEnteredActuals(undefined)
          }}
          selected={currentlyEnteredDataName ? [toOption(currentlyEnteredDataName)] : []}
          options={hideSnowflakeOnEnv(snowflake, groupedOptionsForData)}
        />
        {Boolean(currentScenario?.chainedScenarioId) && Boolean(currentDataScenarioId) ? (
          <ActualDropdown
            year={(parseInt(currentScenario!.year) - 1).toString()}
            division={currentScenario!.division}
            disabled={false}
          />
        ) : (
          <div />
        )}
        <div className="ButtonContainer">
          <Button
            title="Cancel"
            onClick={cancel}
            secondary
          />
          <Button
            title={`Save`}
            onClick={save}
          />
        </div>
      </Card>
    </Modal>
  )
}
