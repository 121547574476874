import { Box, Input, Tbody, Td, Tr } from '@chakra-ui/react'
import { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react'
import { NCPSegments } from '../../backend/new-custom-portfolio-items'
import { PossiblyNegativeNumber } from '../../utils/numbers'
import { TotalName } from '../new-custom-portfolio-splits/NewCustomPortfolioState'
import { sanitizeId } from '../../utils/ids'
import {
  NcpTable,
  NcpTableFoot,
  NcpTableFootCell,
  NcpTableHead,
  NcpTableHeadCell,
  NcpTableReadOnlyInputWithTooltip,
  ReadOnlyInputWithTooltip,
  NcpTableCellTooltip,
} from '../new-custom-portfolio-table'
import { NcpContext, NcpDispatchContext } from '../../providers/NewCustomPortfolioStateProvider'
import { NcpAlertContext, NcpAlertDispatchContext } from '../../providers/NewCustomPortfolioAlertStateProvider'
import { assertNotNil } from '../../utils/assertNotNil'
import { useMarketSegments } from '../../backend/hooks/useMarketSegments'
import { calculateTotalPercentages, calculateTotals } from './segmentsSplitsTableCalculator'
import { NewCustomPortfolioAdjustmentContext } from '../../providers/NewCustomPortfolioAdjustmentsProvider'

export type SegmentSplitsTableProps = {
  currentGwpInput: PossiblyNegativeNumber
  isPreview?: boolean
}

export function SegmentSplitsTable(props: SegmentSplitsTableProps): ReactElement {
  const [focussed, setFocussed] = useState<boolean>(false)
  const ncpState = useContext(NcpContext)
  assertNotNil(ncpState)
  const ncpDispatch = useContext(NcpDispatchContext)
  assertNotNil(ncpDispatch)
  const ncpAlertState = useContext(NcpAlertContext)
  assertNotNil(ncpAlertState)
  const ncpAlertDispatch = useContext(NcpAlertDispatchContext)
  assertNotNil(ncpAlertDispatch)
  const { isNcpPreview } = useContext(NewCustomPortfolioAdjustmentContext)
  const marketSegments = useMarketSegments()

  const ncpData = ncpState.ncpData

  const onFocus = () => {
    setFocussed(true)
  }

  const onBlur = () => {
    setFocussed(false)
  }

  const validateInput = (event: ChangeEvent<HTMLInputElement>): number => {
    let inputValue = event.target.value
    inputValue = inputValue.replace(/^0+/, '')
    if (inputValue.indexOf('.') !== -1) {
      inputValue = inputValue.slice(0, inputValue.indexOf('.'))
      ncpAlertDispatch({ type: 'setWarning', payload: { title: 'Please do not input decimal values', message: '' } })
    }
    return Number(inputValue)
  }

  const formatInput = (input: number): string => input.toLocaleString('en-GB')

  const calculateGwpPerSegment = () => {
    const segmentSplitsData = ncpData.segments
    segmentSplitsData.map((segmentSplit) => {
      if (props.currentGwpInput === 0) {
        segmentSplit.gwp = 0
        return segmentSplit
      }
      segmentSplit.gwp = (segmentSplit.gwpPercent / 100) * Number(props.currentGwpInput)
      return segmentSplit
    })
    ncpDispatch({
      type: 'updateSegmentData',
      payload: { segmentSplitsData: segmentSplitsData },
    })
  }

  useEffect(() => {
    calculateGwpPerSegment()
    ncpDispatch({
      type: 'updateTotals',
      payload: { totalName: 'totalGwp', totalValue: calculateTotals(ncpData.segments, 'gwp') },
    })
  }, [props.currentGwpInput])

  useEffect(() => {
    // recalculate weighted total percentages
    const fieldsToUpdate: { totalName: TotalName; segmentValue: keyof Omit<NCPSegments, 'name'> }[] = [
      { totalName: 'totalAvgGwpPerLayer', segmentValue: 'avgGwpPerLayer' },
      { totalName: 'totalAvgConvexLimit', segmentValue: 'avgConvexLimit' },
      { totalName: 'totalAvgAttachmentPoint', segmentValue: 'avgAttachmentPoint' },
      { totalName: 'totalEulrPercent', segmentValue: 'eulrPercent' },
      { totalName: 'totalWrittenLinePercent', segmentValue: 'writtenLinePercent' },
      { totalName: 'totalAcqCostPercent', segmentValue: 'acqCostPercent' },
      { totalName: 'totalGrarcPercent', segmentValue: 'grarcPercent' },
    ]
    fieldsToUpdate.forEach((field) => {
      ncpDispatch({
        type: 'updateTotals',
        payload: {
          totalName: field.totalName,
          totalValue: calculateTotalPercentages(
            ncpData.segments.map((segmentSplit) => segmentSplit[field.segmentValue]),
            ncpData.segments.map((segmentSplit) => segmentSplit.gwp),
          ),
        },
      })
    })
  }, [ncpData.totalGwp])

  const footerCells: ReadOnlyInputWithTooltip[] = [
    { id: 'Total GWP Percent', value: ncpData.totalGwpPercent, style: 'none' },
    { id: 'Total GWP', value: formatInput(ncpData.totalGwp ?? 0) },
    { id: 'Total Average GWP per Layer', value: formatInput(ncpData.totalAvgGwpPerLayer ?? 0) },
    { id: 'Total Average Convex Limit', value: formatInput(ncpData.totalAvgConvexLimit) ?? 0 },
    { id: 'Total Average Attachment Point', value: formatInput(ncpData.totalAvgAttachmentPoint) ?? 0 },
    { id: 'Total EULR Percent', value: ncpData.totalEulrPercent ?? 0 },
    { id: 'Total Written Line Percent', value: ncpData.totalWrittenLinePercent ?? 0 },
    { id: 'Total Acq Cost Percent', value: ncpData.totalAcqCostPercent ?? 0 },
    { id: 'Total GRARC Percent', value: ncpData.totalGrarcPercent ?? 0 },
  ]

  return (
    <NcpTable
      title="Segment Splits"
      subtitle={props.isPreview ? 'Enter GWP % to calculate GWP $ for each segment' : ''}
    >
      <SegmentSplitTableHeader />
      <Tbody>
        {marketSegments.map((marketSegment, index) => {
          const segment = ncpData.segments.find((segmentSplit) => segmentSplit.name === marketSegment.segmentName)
          return (
            <Tr key={index}>
              <Td
                style={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 2,
                  background: '#fff',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textTransform: 'none',
                }}
              >
                {marketSegment.segmentName}
              </Td>
              <Td p="4px">
                <Input
                  size={'sm'}
                  borderRadius={'md'}
                  id={sanitizeId(`${marketSegment.segmentName} GWP Percent`)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  type={focussed ? 'number' : 'text'}
                  value={segment?.gwpPercent}
                  onChange={(event) => {
                    const segmentSplitsData = ncpData.segments
                    segmentSplitsData.map((segmentSplit) => {
                      if (segmentSplit.name === marketSegment.segmentName) {
                        segmentSplit.gwpPercent = validateInput(event)
                        segmentSplit.gwp = Math.round((segmentSplit.gwpPercent / 100) * Number(props.currentGwpInput))
                        if (segmentSplit.gwpPercent === 0) {
                          segmentSplit.avgGwpPerLayer = 0
                          segmentSplit.avgConvexLimit = 0
                          segmentSplit.avgAttachmentPoint = 0
                          segmentSplit.eulrPercent = 0
                          segmentSplit.writtenLinePercent = 0
                          segmentSplit.acqCostPercent = 0
                          segmentSplit.grarcPercent = 0
                        }
                      }
                      return segmentSplit
                    })
                    ncpDispatch({
                      type: 'updateSegmentData',
                      payload: { segmentSplitsData: segmentSplitsData },
                    })
                    ncpDispatch({
                      type: 'updateTotals',
                      payload: { totalName: 'totalGwp', totalValue: calculateTotals(segmentSplitsData, 'gwp') },
                    })
                  }}
                  isInvalid={
                    ncpAlertState.field.includes('gwpPercent') || ncpAlertState.field.includes('totalGwpPercent')
                  }
                  disabled={isNcpPreview}
                />
              </Td>
              <Td p="4px">
                <NcpTableCellTooltip label={formatInput(segment?.gwp || 0)}>
                  <Input
                    size={'sm'}
                    borderRadius={'md'}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    disabled
                    id={sanitizeId(`${marketSegment.segmentName} GWP`)}
                    value={formatInput(segment?.gwp || 0)}
                    type="text"
                  />
                </NcpTableCellTooltip>
              </Td>
              <Td p="4px">
                <NcpTableCellTooltip label={formatInput(segment?.avgGwpPerLayer || 0)}>
                  <Input
                    size={'sm'}
                    borderRadius={'md'}
                    id={sanitizeId(`${marketSegment.segmentName} Average GWP per layer`)}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={focussed ? 'number' : 'text'}
                    value={focussed ? segment?.avgGwpPerLayer : formatInput(segment?.avgGwpPerLayer || 0)}
                    disabled={isNcpPreview || segment?.gwpPercent === 0}
                    onChange={(event) => {
                      const segmentSplitsData = ncpData.segments
                      segmentSplitsData.map((segmentSplit) => {
                        if (segmentSplit.name === marketSegment.segmentName) {
                          segmentSplit.avgGwpPerLayer = validateInput(event)
                        }
                        return segmentSplit
                      })
                      ncpDispatch({
                        type: 'updateSegmentData',
                        payload: { segmentSplitsData: segmentSplitsData },
                      })
                      ncpDispatch({
                        type: 'updateTotals',
                        payload: {
                          totalName: 'totalAvgGwpPerLayer',
                          totalValue: Math.round(
                            calculateTotalPercentages(
                              segmentSplitsData.map((segmentSplit) => segmentSplit.avgGwpPerLayer),
                              segmentSplitsData.map((segmentSplit) => segmentSplit.gwp),
                            ),
                          ),
                        },
                      })
                    }}
                    isInvalid={
                      segment?.gwpPercent !== 0 &&
                      (ncpAlertState.field.includes('avgGwpPerLayer') ||
                        ncpAlertState.field.includes('totalAvgGwpPerLayer'))
                    }
                  />
                </NcpTableCellTooltip>
              </Td>
              <Td p="4px">
                <NcpTableCellTooltip label={formatInput(segment?.avgConvexLimit || 0)}>
                  <Input
                    size={'sm'}
                    borderRadius={'md'}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    id={sanitizeId(`${marketSegment.segmentName} Average Convex Limit`)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={focussed ? 'number' : 'text'}
                    value={focussed ? segment?.avgConvexLimit : formatInput(segment?.avgConvexLimit || 0)}
                    disabled={isNcpPreview || segment?.gwpPercent === 0}
                    onChange={(event) => {
                      const segmentSplitsData = ncpData.segments
                      segmentSplitsData.map((segmentSplit) => {
                        if (segmentSplit.name === marketSegment.segmentName) {
                          segmentSplit.avgConvexLimit = validateInput(event)
                        }
                        return segmentSplit
                      })
                      ncpDispatch({
                        type: 'updateSegmentData',
                        payload: { segmentSplitsData: segmentSplitsData },
                      })
                      ncpDispatch({
                        type: 'updateTotals',
                        payload: {
                          totalName: 'totalAvgConvexLimit',
                          totalValue: Math.round(
                            calculateTotalPercentages(
                              segmentSplitsData.map((segmentSplit) => segmentSplit.avgConvexLimit),
                              segmentSplitsData.map((segmentSplit) => segmentSplit.gwp),
                            ),
                          ),
                        },
                      })
                    }}
                    isInvalid={
                      segment?.gwpPercent !== 0 &&
                      (ncpAlertState.field.includes('avgConvexLimit') ||
                        ncpAlertState.field.includes('totalAvgConvexLimit'))
                    }
                  />
                </NcpTableCellTooltip>
              </Td>
              <Td p="4px">
                <NcpTableCellTooltip label={formatInput(segment?.avgAttachmentPoint || 0)}>
                  <Input
                    size={'sm'}
                    borderRadius={'md'}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    id={sanitizeId(`${marketSegment.segmentName} Average Attachment Point`)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={focussed ? 'number' : 'text'}
                    value={focussed ? segment?.avgAttachmentPoint : formatInput(segment?.avgAttachmentPoint || 0)}
                    disabled={isNcpPreview || segment?.gwpPercent === 0}
                    onChange={(event) => {
                      const segmentSplitsData = ncpData.segments
                      segmentSplitsData.map((segmentSplit) => {
                        if (segmentSplit.name === marketSegment.segmentName) {
                          segmentSplit.avgAttachmentPoint = validateInput(event)
                        }
                        return segmentSplit
                      })
                      ncpDispatch({
                        type: 'updateSegmentData',
                        payload: { segmentSplitsData: segmentSplitsData },
                      })
                      ncpDispatch({
                        type: 'updateTotals',
                        payload: {
                          totalName: 'totalAvgAttachmentPoint',
                          totalValue: Math.round(
                            calculateTotalPercentages(
                              segmentSplitsData.map((segmentSplit) => segmentSplit.avgAttachmentPoint),
                              segmentSplitsData.map((segmentSplit) => segmentSplit.gwp),
                            ),
                          ),
                        },
                      })
                    }}
                    isInvalid={
                      segment?.gwpPercent !== 0 &&
                      (ncpAlertState.field.includes('avgAttachmentPoint') ||
                        ncpAlertState.field.includes('totalAvgAttachmentPoint'))
                    }
                  />
                </NcpTableCellTooltip>
              </Td>
              <Td p="4px">
                <NcpTableCellTooltip label={segment?.eulrPercent || 0}>
                  <Input
                    size={'sm'}
                    borderRadius={'md'}
                    id={sanitizeId(`${marketSegment.segmentName} EULR Percent`)}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={focussed ? 'number' : 'text'}
                    value={segment?.eulrPercent}
                    disabled={isNcpPreview || segment?.gwpPercent === 0}
                    onChange={(event) => {
                      const segmentSplitsData = ncpData.segments
                      segmentSplitsData.map((segmentSplit) => {
                        if (segmentSplit.name === marketSegment.segmentName) {
                          segmentSplit.eulrPercent = Number(event.target.value)
                        }
                        return segmentSplit
                      })
                      ncpDispatch({
                        type: 'updateSegmentData',
                        payload: { segmentSplitsData: segmentSplitsData },
                      })
                      ncpDispatch({
                        type: 'updateTotals',
                        payload: {
                          totalName: 'totalEulrPercent',
                          totalValue: calculateTotalPercentages(
                            segmentSplitsData.map((segmentSplit) => segmentSplit.eulrPercent),
                            segmentSplitsData.map((segmentSplit) => segmentSplit.gwp),
                          ),
                        },
                      })
                    }}
                    isInvalid={
                      segment?.gwpPercent !== 0 &&
                      (ncpAlertState.field.includes('eulrPercent') || ncpAlertState.field.includes('totalEulrPercent'))
                    }
                  />
                </NcpTableCellTooltip>
              </Td>
              <Td p="4px">
                <NcpTableCellTooltip label={segment?.writtenLinePercent || 0}>
                  <Input
                    size={'sm'}
                    borderRadius={'md'}
                    id={sanitizeId(`${marketSegment.segmentName} Written Line Percent`)}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={focussed ? 'number' : 'text'}
                    value={segment?.writtenLinePercent}
                    disabled={isNcpPreview || segment?.gwpPercent === 0}
                    onChange={(event) => {
                      const segmentSplitsData = ncpData.segments
                      segmentSplitsData.map((segmentSplit) => {
                        if (segmentSplit.name === marketSegment.segmentName) {
                          segmentSplit.writtenLinePercent = Number(event.target.value)
                        }
                        return segmentSplit
                      })
                      ncpDispatch({
                        type: 'updateSegmentData',
                        payload: { segmentSplitsData: segmentSplitsData },
                      })
                      ncpDispatch({
                        type: 'updateTotals',
                        payload: {
                          totalName: 'totalWrittenLinePercent',
                          totalValue: calculateTotalPercentages(
                            segmentSplitsData.map((segmentSplit) => segmentSplit.writtenLinePercent),
                            segmentSplitsData.map((segmentSplit) => segmentSplit.gwp),
                          ),
                        },
                      })
                    }}
                    isInvalid={
                      segment?.gwpPercent !== 0 &&
                      (ncpAlertState.field.includes('writtenLinePercent') ||
                        ncpAlertState.field.includes('totalWrittenLinePercent'))
                    }
                  />
                </NcpTableCellTooltip>
              </Td>
              <Td p="4px">
                <NcpTableCellTooltip label={segment?.acqCostPercent || 0}>
                  <Input
                    size={'sm'}
                    borderRadius={'md'}
                    id={sanitizeId(`${marketSegment.segmentName} Acq Cost Percent`)}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={focussed ? 'number' : 'text'}
                    value={segment?.acqCostPercent}
                    disabled={isNcpPreview || segment?.gwpPercent === 0}
                    onChange={(event) => {
                      const segmentSplitsData = ncpData.segments
                      segmentSplitsData.map((segmentSplit) => {
                        if (segmentSplit.name === marketSegment.segmentName) {
                          segmentSplit.acqCostPercent = Number(event.target.value)
                        }
                        return segmentSplit
                      })
                      ncpDispatch({
                        type: 'updateSegmentData',
                        payload: { segmentSplitsData: segmentSplitsData },
                      })
                      ncpDispatch({
                        type: 'updateTotals',
                        payload: {
                          totalName: 'totalAcqCostPercent',
                          totalValue: calculateTotalPercentages(
                            segmentSplitsData.map((segmentSplit) => segmentSplit.acqCostPercent),
                            segmentSplitsData.map((segmentSplit) => segmentSplit.gwp),
                          ),
                        },
                      })
                    }}
                    isInvalid={
                      segment?.gwpPercent !== 0 &&
                      (ncpAlertState.field.includes('acqCostPercent') ||
                        ncpAlertState.field.includes('totalAcqCostPercent'))
                    }
                  />
                </NcpTableCellTooltip>
              </Td>
              <Td p="4px">
                <NcpTableCellTooltip label={segment?.grarcPercent || 0}>
                  <Input
                    size={'sm'}
                    borderRadius={'md'}
                    id={sanitizeId(`${marketSegment.segmentName} GRARC Percent`)}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={focussed ? 'number' : 'text'}
                    value={segment?.grarcPercent}
                    disabled={isNcpPreview || segment?.gwpPercent === 0}
                    onChange={(event) => {
                      const segmentSplitsData = ncpData.segments
                      segmentSplitsData.map((segmentSplit) => {
                        if (segmentSplit.name === marketSegment.segmentName) {
                          segmentSplit.grarcPercent = Number(event.target.value)
                        }
                        return segmentSplit
                      })
                      ncpDispatch({
                        type: 'updateSegmentData',
                        payload: { segmentSplitsData: segmentSplitsData },
                      })
                      ncpDispatch({
                        type: 'updateTotals',
                        payload: {
                          totalName: 'totalGrarcPercent',
                          totalValue: calculateTotalPercentages(
                            segmentSplitsData.map((segmentSplit) => segmentSplit.grarcPercent),
                            segmentSplitsData.map((segmentSplit) => segmentSplit.gwp),
                          ),
                        },
                      })
                    }}
                    isInvalid={
                      segment?.gwpPercent !== 0 &&
                      (ncpAlertState.field.includes('grarcPercent') ||
                        ncpAlertState.field.includes('totalGrarcPercent'))
                    }
                  />
                </NcpTableCellTooltip>
              </Td>
            </Tr>
          )
        })}
      </Tbody>
      <NcpTableFoot>
        <NcpTableFootCell sticky>Total</NcpTableFootCell>
        {footerCells.map((cell, index) => (
          <NcpTableFootCell key={index}>
            <NcpTableReadOnlyInputWithTooltip {...cell} />
          </NcpTableFootCell>
        ))}
      </NcpTableFoot>
    </NcpTable>
  )
}

const SegmentSplitTableHeader = () => {
  return (
    <NcpTableHead>
      <NcpTableHeadCell stickyOffset={0}>
        <Box width={'150px'}>Segment</Box>
      </NcpTableHeadCell>
      <NcpTableHeadCell>GWP %</NcpTableHeadCell>
      <NcpTableHeadCell>
        <Box width={'80px'}>GWP $</Box>
      </NcpTableHeadCell>
      <NcpTableHeadCell>
        <Box width={'80px'}>Average GWP per layer $</Box>
      </NcpTableHeadCell>
      <NcpTableHeadCell>
        <Box width={'80px'}>Average Convex Limit $</Box>
      </NcpTableHeadCell>
      <NcpTableHeadCell>
        <Box width={'80px'}>Average Attachment Point $</Box>
      </NcpTableHeadCell>
      <NcpTableHeadCell>EULR %</NcpTableHeadCell>
      <NcpTableHeadCell>Written Line %</NcpTableHeadCell>
      <NcpTableHeadCell>Acq. Cost %</NcpTableHeadCell>
      <NcpTableHeadCell>GRARC %</NcpTableHeadCell>
    </NcpTableHead>
  )
}
