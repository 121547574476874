import { PossiblyNegativeNumber } from '../utils/numbers'
import { FilterValues } from './calculate-possible-filter-values'
import { getCurrentConfigSettings } from '../utils/config'
import { patchScenario } from './scenarios'
import { getAccessToken } from './teams'
import { isSameObject } from '../utils/objectComparison'

export const PREVIEW_ID = 'preview'

export const isNotPreview = (adjustment: Adjustment): boolean => PREVIEW_ID !== adjustment.id

export const isActive = (adjustment: Adjustment): boolean => !!adjustment.isEnabled

export const isClient = (adjustment: Adjustment): boolean => adjustment.subtype === 'CLIENT_SPECIFIC'

export const previewPortfolioAdjustmentIsUntouched = (adjustment: Adjustment): boolean => {
  return isSameObject(adjustment, defaultPreviewPortfolioAdjustment)
}

export interface Adjustment {
  id: string
  name: string
  orderNumber: number
  description: string
  riskAdjustedPremiumRateChange: number
  pureGrossRateChange: number
  convexShareChange: number
  convexWrittenShareChange: number
  appliedFilters: FilterValues
  updatedDate: string
  isEnabled?: boolean
  subtype: string
  retention: number
}

export const defaultPreviewPortfolioAdjustment: Omit<Adjustment, 'orderNumber'> = {
  id: PREVIEW_ID,
  name: '',
  description: '',
  riskAdjustedPremiumRateChange: 0,
  pureGrossRateChange: 0,
  convexShareChange: 0,
  convexWrittenShareChange: 0,
  appliedFilters: {},
  updatedDate: 'not-yet-updated',
  isEnabled: true,
  subtype: 'NORMAL',
  retention: 0,
}

export interface AdjustmentToBePreviewed {
  riskAdjustedPremiumRateChange: PossiblyNegativeNumber
  pureGrossRateChange: PossiblyNegativeNumber
  convexShareChange: PossiblyNegativeNumber
  appliedFilters: FilterValues
}

export interface PatchAdjustmentRequest {
  name: string
  description?: string
  riskAdjustedPremiumRateChange?: string
  pureGrossRateChange?: string
  convexShareChange?: string
  convexWrittenShareChange?: string
  appliedFilters?: FilterValues
  subtype?: string
  retention?: string
  isEnabled?: boolean
  orderNumber?: number
}

export interface CreateOrUpdateAdjustmentRequest {
  id?: string
  appliedFilters: FilterValues
  name: string
  convexShareChange: number
  convexWrittenShareChange: number
  riskAdjustedPremiumRateChange: number
  pureGrossRateChange: number
  retention?: number
  description: string
  subtype?: string
  orderNumber?: number
}

function calculateGrossRiskAdjustedRateChange(pureGrossRateChange: number, riskAdjustedPremiumRateChange: number) {
  let ggRateChangeForCalc = pureGrossRateChange ? pureGrossRateChange : 0
  let riskAdjRateChangeForCalc = riskAdjustedPremiumRateChange ? riskAdjustedPremiumRateChange : 0
  let grarc = ((1 + ggRateChangeForCalc / 100) / (1 + riskAdjRateChangeForCalc / 100) - 1) * 100
  return Math.round((grarc + Number.EPSILON) * 100) / 100
}

function sortAdjustmentsClientSpecificFirstThenByOldestFirst(inputAdjustments: Adjustment[]): Adjustment[] {
  const clientSpecificAdjustments = [...inputAdjustments].filter((item) => item.subtype === 'CLIENT_SPECIFIC')
  const otherAdjustments = [...inputAdjustments].filter((item) => item.subtype !== 'CLIENT_SPECIFIC')

  return [...sortAdjustmentsByOldestFirst(clientSpecificAdjustments), ...sortAdjustmentsByOldestFirst(otherAdjustments)]
}

function sortAdjustmentsByOldestFirst(inputAdjustments: Adjustment[]): Adjustment[] {
  return [...inputAdjustments].sort((first, second) => first.orderNumber - second.orderNumber)
}

function sortAdjustmentsByNewestFirst(inputAdjustments: Adjustment[]): Adjustment[] {
  return [...inputAdjustments].sort((first, second) => second.orderNumber - first.orderNumber)
}

const isRelevantAdjustment = (adjustmentId: string) => (adjustment: Adjustment) => {
  return adjustment.id === adjustmentId
}

const isIrrelevantAdjustment = (adjustmentId: string) => (adjustment: Adjustment) => {
  return adjustment.id !== adjustmentId
}

async function patchAdjustment(
  scenarioId: string,
  adjustmentId: string,
  patchAdjustmentRequest: PatchAdjustmentRequest,
) {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/renewal/adjustments/${adjustmentId}`,
    {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
      body: JSON.stringify(patchAdjustmentRequest),
    },
  )

  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}

  return await response.json()
}

async function createAdjustment(scenarioId: string, createAdjustmentRequest: CreateOrUpdateAdjustmentRequest) {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/renewal/adjustments`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
    body: JSON.stringify(createAdjustmentRequest),
  })

  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}

  return await response.json()
}

async function deleteAdjustment(scenarioId: string, adjustmentId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/renewal/adjustments/${adjustmentId}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  try {
    patchScenario(scenarioId, {})
  } catch (ignored) {}
}

async function deleteAllAdjustments(scenarioId: string) {
  await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/renewal/adjustments`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })
}

async function fetchAdjustments(scenarioId: string): Promise<Adjustment[]> {
  const response = await fetch(`${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/renewal/adjustments`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAccessToken(),
    },
  })

  return await response.json()
}

async function fetchAdjustment(scenarioId: string, adjustmentId: string): Promise<Adjustment | undefined> {
  const response = await fetch(
    `${getCurrentConfigSettings().apiHost}/scenarios/${scenarioId}/renewal/adjustments/${adjustmentId}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await getAccessToken(),
      },
    },
  )

  return await response.json()
}

export {
  fetchAdjustments,
  fetchAdjustment,
  createAdjustment,
  patchAdjustment,
  deleteAdjustment,
  deleteAllAdjustments,
  sortAdjustmentsByOldestFirst,
  sortAdjustmentsByNewestFirst,
  sortAdjustmentsClientSpecificFirstThenByOldestFirst,
  isRelevantAdjustment,
  isIrrelevantAdjustment,
  calculateGrossRiskAdjustedRateChange,
}
