import { useContext, useEffect, useMemo } from 'react'
import ColumnAndValueFilter from '../column-and-value-filter/ColumnAndValueFilter'
import './ScenarioFilterCard.scss'
import {
  calculatePossibleNumericFilterValues,
  calculatePossibleStringFilterValuesWithExtras,
  combineFilters,
  FilterType,
  FilterValues,
} from '../../backend/calculate-possible-filter-values'
import { FilterContext } from '../../providers/FilterProvider'
import { GraphFilterDataContext } from '../renewal-graph/GraphFilterDataProvider'
import { filterRowByEachColumn } from '../../backend/calculate-kpis'
import { Button } from '../react_library_button/Button'
import { RawEntry } from '../../backend/rawEntry'
import SegmentedControl from '../segmented-control/SegmentedControl'
import { formatEnumForDisplay } from '../ScenarioNavigationMenuItem/ScenarioNavigationMenuItem'
import { UrlUpdateType } from 'use-query-params'
import { OutwardScenarioRuleContext } from '../../providers/outwards/OutwardScenarioRulesProvider'
import { calculateFilteredDataApplyingRulesOneAfterAnother } from '../../providers/outwards/OutwardsDataProvider'
import { RULE_PREVIEW_ID } from '../outward-rule-card/OutwardRuleCard'
import { NewBusinessTypeWithCustomPortfolio } from 'src/pages/new/New'
import { IndividualFilterValue, ManyDropdownFilterValues } from '../../providers/FilterProvider/model'

type BusinessType = string

export interface ScenarioFilterCardProps {
  isDisabled?: boolean
  hideMultiSelect?: any
  extraFilterValues?: string[]
  outward?: true
  availableBusinessTypes?: Array<BusinessType>
  currentBusinessType?: string | null | undefined
  setBusinessType?: (newValue: NewBusinessTypeWithCustomPortfolio, updateType?: UrlUpdateType | undefined) => void
}

export const ScenarioFilterCard = (props: ScenarioFilterCardProps) => {
  const { possibleFilterValues, fullDataForScenario, extraColumnsToInclude, setExtraColumnsToInclude } =
    useContext(GraphFilterDataContext)
  const { rules } = useContext(OutwardScenarioRuleContext)
  const { dropdownFilterOptions, onChangeFilters } = useContext(FilterContext)

  const dataToUseRightNow = useMemo(() => {
    const nonPreviewRules = rules?.filter((item) => item.id !== RULE_PREVIEW_ID)

    if (!nonPreviewRules?.length) {
      return fullDataForScenario
    }

    return calculateFilteredDataApplyingRulesOneAfterAnother(fullDataForScenario, nonPreviewRules)
  }, [fullDataForScenario, rules])

  useEffect(() => {
    setExtraColumnsToInclude(props.extraFilterValues || [])
  }, [props.extraFilterValues, setExtraColumnsToInclude])

  // TODO same code snippet as Client - suggestion to extract it into a hook of its own to reuse in both files
  const updateIndividualFilter =
    (filterId: string) =>
    (columnName: string, chosenValue: FilterType): void => {
      let newDropdownFilterOptions: ManyDropdownFilterValues = { ...dropdownFilterOptions }
      if (!columnName || !chosenValue) {
        delete newDropdownFilterOptions[filterId]
      } else {
        const add = { [filterId]: { columnName, chosenValue } }
        newDropdownFilterOptions = { ...newDropdownFilterOptions, ...add }
      }

      const options = removeLowerFilter(filterId, newDropdownFilterOptions)

      onChangeFilters(options)
    }

  const clearFilters = (): void => onChangeFilters({})

  return (
    <div className="ScenarioFilterCard">
      {props?.availableBusinessTypes?.length && (
        <SegmentedControl
          options={props.availableBusinessTypes.map((nbt) => ({
            value: nbt,
            name: formatEnumForDisplay(nbt),
          }))}
          selectedOption={
            props.currentBusinessType ? props.availableBusinessTypes.indexOf(props.currentBusinessType) : 0
          }
          onChange={(item) => {
            if (props.setBusinessType) {
              props.setBusinessType(item as NewBusinessTypeWithCustomPortfolio)
            }
          }}
          className="ScenarioFilterCardSegmentControl"
        />
      )}
      <ColumnAndValueFilter
        name={'filter1'}
        isDisabled={props.isDisabled}
        onSelected={updateIndividualFilter('filter1')}
        current={dropdownFilterOptions}
        options={possibleFilterValues}
        partialOptions={calculateRemainingOptionsToFilterOnBasedOnAvailableData(
          dataToUseRightNow,
          dropdownFilterOptions,
          [],
          extraColumnsToInclude,
        )}
        hideMultiSelect={props.hideMultiSelect}
        canBeNegated={true}
      />
      {dropdownFilterOptions['filter1'] && (
        <ColumnAndValueFilter
          name={'filter2'}
          isDisabled={!dropdownFilterOptions['filter1'] || props.isDisabled}
          onSelected={updateIndividualFilter('filter2')}
          current={dropdownFilterOptions}
          options={possibleFilterValues}
          partialOptions={calculateRemainingOptionsToFilterOnBasedOnAvailableData(
            dataToUseRightNow,
            dropdownFilterOptions,
            ['filter1'],
            extraColumnsToInclude,
          )}
          hideMultiSelect={props.hideMultiSelect}
          canBeNegated={true}
        />
      )}
      {dropdownFilterOptions['filter2'] && (
        <ColumnAndValueFilter
          name={'filter3'}
          isDisabled={!dropdownFilterOptions['filter2'] || props.isDisabled}
          onSelected={updateIndividualFilter('filter3')}
          current={dropdownFilterOptions}
          options={possibleFilterValues}
          partialOptions={calculateRemainingOptionsToFilterOnBasedOnAvailableData(
            dataToUseRightNow,
            dropdownFilterOptions,
            ['filter1', 'filter2'],
            extraColumnsToInclude,
          )}
          hideMultiSelect={props.hideMultiSelect}
          canBeNegated={true}
        />
      )}
      <Button
        isDisabled={props.isDisabled}
        title="Clear filters"
        secondary={true}
        onClick={clearFilters}
      />
    </div>
  )
}

export function removeLowerFilter(filterId: string, options: ManyDropdownFilterValues): ManyDropdownFilterValues {
  const source = lastCharacter(filterId)
  return Object.entries(options)
    .filter(([id, x]) => {
      const target = lastCharacter(id)
      return source >= target
    })
    .reduce((p, c) => {
      const [filterId, value] = c
      return { ...p, [filterId]: value }
    }, {})
}

function lastCharacter(filterId: string): number {
  const last = filterId.slice(-1)
  return Number(last)
}

export function calculateRemainingOptionsToFilterOnBasedOnAvailableData(
  fullDataForScenario: RawEntry[],
  currentAppliedFilters: ManyDropdownFilterValues,
  allFilterIdsToApply: string[],
  extraColumnsToJustIncludeAnyway: Array<string>,
): FilterValues {
  const filtersToApply: Array<undefined | IndividualFilterValue> = allFilterIdsToApply.map(
    (filterId) => currentAppliedFilters[filterId],
  )

  const rawFiltered: RawEntry[] = filtersToApply
    .filter((item) => item !== undefined)
    .reduce(
      (filteredDataSoFar, nextFilterToApply) =>
        filterRowByEachColumn(filteredDataSoFar, [nextFilterToApply!.columnName, nextFilterToApply!.chosenValue]),
      fullDataForScenario,
    )

  const numericFilterValues = calculatePossibleNumericFilterValues(rawFiltered)
  const stringFilterValues = calculatePossibleStringFilterValuesWithExtras(
    rawFiltered,
    extraColumnsToJustIncludeAnyway,
    false,
  )
  return combineFilters(stringFilterValues, numericFilterValues)
}
