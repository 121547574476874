import { isNumber, isNumberOrZero, isZero, PossiblyNegativeNumber, ZERO } from '../../../utils/numbers'
import { Adjustment, CreateOrUpdateAdjustmentRequest } from '../../../backend/adjustments'
import { FilterValues } from '../../../backend/calculate-possible-filter-values'
import {
  ScenarioKeyPerformanceIndicators,
  TOTAL_GWP_LEFT_TO_ADJUST_COLUMN,
  TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN,
} from '../../../backend/calculate-kpis'
import Big from 'big.js'

export * from './calculate'

export function valueToString(value: unknown): string {
  if (value === undefined || value === null) {
    return ''
  }

  if (typeof value === 'string') {
    return value
  }

  if (typeof value === 'number') {
    return value.toString()
  }

  console.error(`Unexpected value type: ${typeof value}`)
  return ''
}

export type AdjustmentConfig = {
  id: string
  filters: FilterValues
  currentName: string
  convexShareChange: PossiblyNegativeNumber
  convexWrittenShareChange: PossiblyNegativeNumber
  riskAdjustedPremiumRateChange: PossiblyNegativeNumber
  pureGrossRateChange: PossiblyNegativeNumber
  retention: PossiblyNegativeNumber
  description: string
  subtype: string
  existingEntity: Adjustment
}

export function createNewAdjustment(isPreview: boolean, adjustmentConfig: AdjustmentConfig): Adjustment {
  if (isPreview) {
    return {
      id: adjustmentConfig.id,
      appliedFilters: adjustmentConfig.filters,
      name: adjustmentConfig.currentName,
      convexShareChange: isNumberOrZero(adjustmentConfig.convexShareChange),
      convexWrittenShareChange: isNumberOrZero(adjustmentConfig.convexWrittenShareChange),
      riskAdjustedPremiumRateChange: isNumberOrZero(adjustmentConfig.riskAdjustedPremiumRateChange),
      pureGrossRateChange: isNumberOrZero(adjustmentConfig.pureGrossRateChange),
      retention: isNumberOrZero(adjustmentConfig.retention),
      updatedDate: 'not-yet-updated',
      description: adjustmentConfig.description,
      orderNumber: Number.MAX_SAFE_INTEGER,
      isEnabled: true,
      subtype: adjustmentConfig.subtype,
    }
  }

  const convexShareChange = isNumber(adjustmentConfig.convexShareChange)
    ? adjustmentConfig.convexShareChange
    : adjustmentConfig.existingEntity.convexShareChange
  const convexWrittenShareChange = isNumber(adjustmentConfig.convexWrittenShareChange)
    ? adjustmentConfig.convexWrittenShareChange
    : adjustmentConfig.existingEntity.convexWrittenShareChange
  const riskAdjustedPremiumRateChange = isNumber(adjustmentConfig.riskAdjustedPremiumRateChange)
    ? adjustmentConfig.riskAdjustedPremiumRateChange
    : adjustmentConfig.existingEntity.riskAdjustedPremiumRateChange
  const pureGrossRateChange = isNumber(adjustmentConfig.pureGrossRateChange)
    ? adjustmentConfig.pureGrossRateChange
    : adjustmentConfig.existingEntity.pureGrossRateChange
  const retention = isNumber(adjustmentConfig.retention)
    ? adjustmentConfig.retention
    : adjustmentConfig.existingEntity.retention

  return {
    id: adjustmentConfig.id,
    appliedFilters: adjustmentConfig.filters,
    name: adjustmentConfig.currentName || adjustmentConfig.existingEntity.name,
    convexShareChange,
    convexWrittenShareChange,
    riskAdjustedPremiumRateChange,
    pureGrossRateChange,
    retention,
    updatedDate: adjustmentConfig.existingEntity.updatedDate,
    description: adjustmentConfig.description || adjustmentConfig.existingEntity.description,
    orderNumber: adjustmentConfig.existingEntity.orderNumber,
    isEnabled: true,
    subtype: adjustmentConfig.subtype,
  }
}

export function createNewAdjustmentRequest(
  isPreview: boolean,
  adjustmentConfig: AdjustmentConfig,
): CreateOrUpdateAdjustmentRequest {
  if (isPreview) {
    return {
      appliedFilters: adjustmentConfig.filters,
      name: adjustmentConfig.currentName,
      convexShareChange: isNumberOrZero(adjustmentConfig.convexShareChange),
      convexWrittenShareChange: isNumberOrZero(adjustmentConfig.convexWrittenShareChange),
      riskAdjustedPremiumRateChange: isNumberOrZero(adjustmentConfig.riskAdjustedPremiumRateChange),
      pureGrossRateChange: isNumberOrZero(adjustmentConfig.pureGrossRateChange),
      retention: isNumberOrZero(adjustmentConfig.retention),
      description: adjustmentConfig.description,
      orderNumber: undefined,
      subtype: adjustmentConfig.subtype,
    }
  }

  const convexShareChange = isNumber(adjustmentConfig.convexShareChange)
    ? adjustmentConfig.convexShareChange
    : adjustmentConfig.existingEntity.convexShareChange
  const convexWrittenShareChange = isNumber(adjustmentConfig.convexWrittenShareChange)
    ? adjustmentConfig.convexWrittenShareChange
    : adjustmentConfig.existingEntity.convexWrittenShareChange
  const riskAdjustedPremiumRateChange = isNumber(adjustmentConfig.riskAdjustedPremiumRateChange)
    ? adjustmentConfig.riskAdjustedPremiumRateChange
    : adjustmentConfig.existingEntity.riskAdjustedPremiumRateChange
  const pureGrossRateChange = isNumber(adjustmentConfig.pureGrossRateChange)
    ? adjustmentConfig.pureGrossRateChange
    : adjustmentConfig.existingEntity.pureGrossRateChange
  const retention = isNumber(adjustmentConfig.retention)
    ? adjustmentConfig.retention
    : adjustmentConfig.existingEntity.retention

  return {
    appliedFilters: adjustmentConfig.filters,
    name: adjustmentConfig.currentName || adjustmentConfig.existingEntity.name,
    convexShareChange,
    convexWrittenShareChange,
    riskAdjustedPremiumRateChange,
    pureGrossRateChange,
    retention,
    description: adjustmentConfig.description || adjustmentConfig.existingEntity.description,
    orderNumber: adjustmentConfig.existingEntity.orderNumber,
  }
}

export function checkBackendResponse(response: object, setErrorUniqueName: (error: string) => void) {
  if (JSON.stringify(response).toString().includes('Adjustment name already exists for scenario')) {
    setErrorUniqueName('Unique name is required')
    throw new Error('Adjustment name already exists')
  }
}

export function makeAdjustableGwp(
  filteredRenewalKpis: ScenarioKeyPerformanceIndicators | undefined,
  adjustmentBeingEditedId: string,
): Big {
  let adjustableGwp: Big =
    filteredRenewalKpis?.[TOTAL_ORIGINAL_GWP_PER_ADJUSTMENT_COLUMN]?.[adjustmentBeingEditedId] || ZERO

  if (isZero(adjustableGwp)) {
    adjustableGwp = filteredRenewalKpis?.[TOTAL_GWP_LEFT_TO_ADJUST_COLUMN] || ZERO
  }

  return adjustableGwp
}
