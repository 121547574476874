import { useCallback, useContext, useEffect, useState } from 'react'
import { Card } from '../card/Card'
import { css } from '../../utils/css'
import HeadingWithTitle from '../../components/heading-with-title/HeadingWithTitle'
import TextField from '../text-field/WFTextField'
import TextArea from '../../components/text-area/TextArea'
import Button from '../../components/button/Button'
import './CreateAdjustmentCard.scss'
import { onInputUpdateState, onNumberInputUpdateState, onTextAreaUpdateState } from '../../utils/onChange'
import { isValidName } from '../../utils/validity'
import { FilterContext, transformIntoFlatStructure } from '../../providers/FilterProvider'
import { ADJUSTMENT_ID, TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN, TOTAL_GWP_COLUMN } from '../../backend/calculate-kpis'
import { isNumberOrZero, PossiblyNegativeNumber, suffixForValue, ZERO } from '../../utils/numbers'
import {
  calculateGrossRiskAdjustedRateChange,
  createAdjustment,
  fetchAdjustment,
  isIrrelevantAdjustment,
  isRelevantAdjustment,
  patchAdjustment,
  PatchAdjustmentRequest,
  PREVIEW_ID,
} from '../../backend/adjustments'
import { RenewalAdjustmentContext } from '../../providers/RenewalAdjustmentsProvider'
import { ErrorText } from '../text-area/ErrorText'
import { ScenarioContext } from '../../providers/ScenarioProvider'
import { FullScenarioDataContext } from '../../providers/FullScenarioData/FullScenarioDataProvider'
import { validateDescription } from '../create-scenario-card/CreateScenarioCard'
import Big from 'big.js'
import {
  calculateConvexShareChangeError,
  calculateConvexShareChangeErrorRealTime,
  calculateConvexShareChangeWarning,
  calculateErrorIfIsClientSpecificAndNoClientSelected,
  calculatePureGrossRateChangeError,
  calculatePureGrossRateChangeWarning,
  calculateRetentionChangeError,
  calculateRetentionChangeErrorRealTime,
  calculateRiskAdjustedPremiumRateChangeError,
  calculateRiskAdjustedPremiumRateChangeWarning,
  checkBackendResponse,
  createNewAdjustment,
  createNewAdjustmentRequest,
  makeAdjustableGwp,
  valueToString,
} from './utils'
import { isEqualToDefault, CalculationsRelevantAdjustment } from './utils/isEqual'

export type CreateAdjustmentCardProps = {
  adjustmentBeingEditedId: string
  reloadSavedAdjustmentsAndResetAdjustmentsPage: () => void
  adjustmentType: string
  defaultRenewalRetention?: number
}

export function CreateAdjustmentCard(props: CreateAdjustmentCardProps) {
  const [currentName, setName] = useState<string>('')
  const [currentDescription, setDescription] = useState<string>('')
  const [currentRiskAdjustedPremiumRateChange, setRiskAdjustedPremiumRateChange] = useState<PossiblyNegativeNumber>(0)
  const [currentPureGrossRateChange, setPureGrossRateChange] = useState<PossiblyNegativeNumber>(0)
  const [currentRetention, setRetention] = useState<PossiblyNegativeNumber>(
    isNumberOrZero(props.defaultRenewalRetention),
  )

  const [currentConvexShareChange, setConvexShareChange] = useState<PossiblyNegativeNumber>(0)
  const [currentConvexWrittenShareChange, setConvexWrittenShareChange] = useState<PossiblyNegativeNumber>(0)

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [errorUniqueName, setErrorUniqueName] = useState<string>('')

  const { currentScenario } = useContext(ScenarioContext)
  const { dropdownFilterOptions } = useContext(FilterContext)
  const { adjustments, changeAdjustments, currentDefaultAdjustmentNameToUse } = useContext(RenewalAdjustmentContext)
  const { fullDataForScenario, filteredRenewalKpis } = useContext(FullScenarioDataContext)

  const numberOfItemsRelevantToAdjustmentId = fullDataForScenario.filter(
    (item) => item[ADJUSTMENT_ID] === props.adjustmentBeingEditedId,
  ).length
  const isPreview = props.adjustmentBeingEditedId === PREVIEW_ID
  const addOrSaveWord = isPreview ? 'Add' : 'Save'

  useEffect(() => {
    if (currentDefaultAdjustmentNameToUse) {
      setName(currentDefaultAdjustmentNameToUse)
    }
  }, [currentDefaultAdjustmentNameToUse])

  const updatePreviewAdjustment = () => {
    const filters = transformIntoFlatStructure(dropdownFilterOptions)

    const adjustmentsWithoutCurrentOne = (adjustments ? adjustments : []).filter(
      isIrrelevantAdjustment(props.adjustmentBeingEditedId),
    )
    const existingEntity = (adjustments ? adjustments : []).find(isRelevantAdjustment(props.adjustmentBeingEditedId))! // TODO another lie (!)

    const adjustmentToShow = createNewAdjustment(isPreview, {
      id: props.adjustmentBeingEditedId,
      filters: filters,
      currentName,
      convexShareChange: currentConvexShareChange,
      convexWrittenShareChange: currentConvexWrittenShareChange,
      riskAdjustedPremiumRateChange: currentRiskAdjustedPremiumRateChange,
      pureGrossRateChange: currentPureGrossRateChange,
      retention: currentRetention,
      description: currentDescription,
      subtype: props.adjustmentType,
      existingEntity,
    })

    const calculationsRelevantAdjustmentsData: CalculationsRelevantAdjustment = {
      convexShareChange: adjustmentToShow.convexShareChange,
      pureGrossRateChange: adjustmentToShow.pureGrossRateChange,
      riskAdjustedPremiumRateChange: adjustmentToShow.riskAdjustedPremiumRateChange,
      retention: adjustmentToShow.retention,
      appliedFilters: adjustmentToShow.appliedFilters,
    }

    changeAdjustments([
      ...adjustmentsWithoutCurrentOne,
      ...(isEqualToDefault(calculationsRelevantAdjustmentsData) && isPreview ? [] : [adjustmentToShow]),
    ])
  }

  const updateBasedOnNewScenario: () => Promise<void> = useCallback(async () => {
    if (PREVIEW_ID === props.adjustmentBeingEditedId) {
      return
    }

    const savedVersionOfAdjustment = await fetchAdjustment(currentScenario!.id, props.adjustmentBeingEditedId)

    if (!savedVersionOfAdjustment) {
      return
    }

    setShouldValidate(false)
    setName(savedVersionOfAdjustment.name)
    setDescription(savedVersionOfAdjustment.description)
    setConvexShareChange(savedVersionOfAdjustment.convexShareChange)
    setConvexWrittenShareChange(savedVersionOfAdjustment.convexWrittenShareChange)
    setRiskAdjustedPremiumRateChange(savedVersionOfAdjustment.riskAdjustedPremiumRateChange)
    setPureGrossRateChange(savedVersionOfAdjustment.pureGrossRateChange)
    setRetention(savedVersionOfAdjustment.retention)
  }, [currentScenario, props.adjustmentBeingEditedId])

  useEffect(updatePreviewAdjustment, [
    currentRiskAdjustedPremiumRateChange,
    currentPureGrossRateChange,
    currentConvexShareChange,
    currentRetention,
    dropdownFilterOptions,
  ])

  useEffect(() => {
    updateBasedOnNewScenario()
  }, [updateBasedOnNewScenario])

  async function validateThenCreateOrUpdateAdjustment() {
    setShouldValidate(true)

    if (
      calculateConvexShareChangeError(currentConvexShareChange) ||
      calculateConvexShareChangeErrorRealTime(currentConvexShareChange) ||
      calculatePureGrossRateChangeError(currentPureGrossRateChange) ||
      calculateRiskAdjustedPremiumRateChangeError(currentRiskAdjustedPremiumRateChange) ||
      calculateRetentionChangeError(currentRetention) ||
      numberOfItemsRelevantToAdjustmentId === 0 ||
      !isValidName(currentName) ||
      validateDescription(currentDescription) ||
      calculateErrorIfIsClientSpecificAndNoClientSelected(props.adjustmentType, dropdownFilterOptions)
    ) {
      return
    }

    const filters = transformIntoFlatStructure(dropdownFilterOptions)
    const existingEntity = (adjustments ? adjustments : []).find(isRelevantAdjustment(props.adjustmentBeingEditedId))!

    try {
      if (!currentScenario) {
        console.error(`Missing currentScenario - unable to create or patch adjustment`)
        return
      }

      if (isPreview) {
        const adjustmentToCreate = createNewAdjustmentRequest(isPreview, {
          id: '',
          filters: filters,
          currentName,
          convexShareChange: currentConvexShareChange,
          convexWrittenShareChange: currentConvexWrittenShareChange,
          riskAdjustedPremiumRateChange: currentRiskAdjustedPremiumRateChange,
          pureGrossRateChange: currentPureGrossRateChange,
          retention: currentRetention,
          description: currentDescription,
          subtype: props.adjustmentType,
          existingEntity,
        })

        const res = await createAdjustment(currentScenario.id, adjustmentToCreate)
        checkBackendResponse(res, (error: string) => setErrorUniqueName(error))
        cancelEditing()
        return
      }

      const adjustmentToCreate: PatchAdjustmentRequest = {
        name: currentName,
        description: currentDescription,
        convexShareChange: valueToString(currentConvexShareChange),
        convexWrittenShareChange: valueToString(currentConvexWrittenShareChange),
        riskAdjustedPremiumRateChange: valueToString(currentRiskAdjustedPremiumRateChange),
        pureGrossRateChange: valueToString(currentPureGrossRateChange),
        retention: valueToString(currentRetention),
        isEnabled: true,
        orderNumber: existingEntity.orderNumber,
        subtype: props.adjustmentType,
        appliedFilters: filters,
      }

      const res = await patchAdjustment(currentScenario.id, props.adjustmentBeingEditedId, adjustmentToCreate)
      checkBackendResponse(res, (error: string) => setErrorUniqueName(error))
      cancelEditing()
    } catch (err: unknown) {
      if (typeof err === 'object' && err && 'message' in err && typeof err.message === 'string') {
        console.error(
          `An error occurred whilst creating or patching an adjustment, adjustmentBeingEditedId: ${props.adjustmentBeingEditedId} - ${err.message}`,
        )
        return
      }

      console.error(`An unknown error occurred, adjustmentBeingEditedId: ${props.adjustmentBeingEditedId}`)
    }
  }

  const cancelEditing = () => {
    props.reloadSavedAdjustmentsAndResetAdjustmentsPage ? props.reloadSavedAdjustmentsAndResetAdjustmentsPage() : void 0

    setName('')
    setDescription('')
    setRiskAdjustedPremiumRateChange(0)
    setPureGrossRateChange(0)
    setConvexShareChange(0)
    setConvexWrittenShareChange(0)
    setRetention(0)
    setShouldValidate(false)
    setErrorUniqueName('')
  }

  const clientSpecificErrorText = calculateErrorIfIsClientSpecificAndNoClientSelected(
    props.adjustmentType,
    dropdownFilterOptions,
  )
  const adjustableGwp: Big = makeAdjustableGwp(filteredRenewalKpis, props.adjustmentBeingEditedId)

  let ggRateChangeForCalc = currentPureGrossRateChange ? (currentPureGrossRateChange as number) : 0
  let riskAdjRateChangeForCalc = currentRiskAdjustedPremiumRateChange
    ? (currentRiskAdjustedPremiumRateChange as number)
    : 0
  let grarc = calculateGrossRiskAdjustedRateChange(ggRateChangeForCalc, riskAdjRateChangeForCalc)

  return (
    <div
      className={css(
        'CreateAdjustmentCard',
        props.adjustmentBeingEditedId !== PREVIEW_ID ? 'CreateAdjustmentCardEditing' : '',
      )}
    >
      <Card>
        <h3 className="CreateAdjustmentCardTitle">
          {props.adjustmentBeingEditedId === PREVIEW_ID ? 'Add Adjustment' : 'Edit Adjustment'}
        </h3>
        <div className="NumbersPrimary">
          <HeadingWithTitle
            title="Original GWP"
            id="Renewal-Adjustment-Card-Header-Original-GWP"
            value={filteredRenewalKpis ? filteredRenewalKpis[TOTAL_GWP_COLUMN] : ZERO}
            decimals={0}
            className="GWP"
            prefix="$"
            suffix={filteredRenewalKpis ? suffixForValue(filteredRenewalKpis[TOTAL_GWP_COLUMN]) : ''}
          />
          <HeadingWithTitle
            title="Adjustable GWP"
            id="Renewal-Adjustment-Card-Header-Adjustable-GWP"
            value={adjustableGwp}
            decimals={0}
            className="Adjust"
            prefix="$"
            suffix={suffixForValue(adjustableGwp)}
          />
        </div>
        <div className="MiddleOptions">
          <TextField
            title="Risk Adj Prem Change"
            id="Renewal-Adjustment-Card-TextField-Risk-Adj-Prem-Change"
            className="RiskAdjustedPremiumChange"
            value={currentRiskAdjustedPremiumRateChange}
            onChange={onNumberInputUpdateState(setRiskAdjustedPremiumRateChange)}
            warning={calculateRiskAdjustedPremiumRateChangeWarning(currentRiskAdjustedPremiumRateChange)}
            error={
              shouldValidate ? calculateRiskAdjustedPremiumRateChangeError(currentRiskAdjustedPremiumRateChange) : ''
            }
            type="number"
            percentage
          />
          <TextField
            title="GG Premium Change"
            id="Renewal-Adjustment-Card-TextField-GG-Premium-Change"
            className="PremiumChange"
            value={currentPureGrossRateChange}
            onChange={onNumberInputUpdateState(setPureGrossRateChange)}
            warning={calculatePureGrossRateChangeWarning(currentPureGrossRateChange)}
            error={shouldValidate ? calculatePureGrossRateChangeError(currentPureGrossRateChange) : ''}
            type="number"
            percentage
          />
          <TextField
            title="Convex Signed Share Change"
            id="Renewal-Adjustment-Card-TextField-Convex-Signed-Share-Change"
            className="ShareChange"
            type="number"
            value={currentConvexShareChange}
            onChange={onNumberInputUpdateState(setConvexShareChange)}
            warning={calculateConvexShareChangeWarning(currentConvexShareChange)}
            error={
              calculateConvexShareChangeErrorRealTime(currentConvexShareChange) ||
              (shouldValidate ? calculateConvexShareChangeError(currentConvexShareChange) : '')
            }
            percentage
          />
          <TextField
            title="Convex Written Share Change"
            id="Renewal-Adjustment-Card-TextField-Convex-Written-Share-Change"
            className="ShareChange"
            type="number"
            value={currentConvexWrittenShareChange}
            onChange={onNumberInputUpdateState(setConvexWrittenShareChange)}
            warning={calculateConvexShareChangeWarning(currentConvexWrittenShareChange)}
            percentage
          />
          <TextField
            title="Renewal Retention"
            id="Renewal-Adjustment-Card-TextField-Renewal-Retention"
            className="RenewalRetention"
            type="number"
            value={currentRetention}
            onChange={onNumberInputUpdateState(setRetention)}
            error={
              calculateRetentionChangeErrorRealTime(currentRetention) ||
              (shouldValidate ? calculateRetentionChangeError(currentRetention) : '')
            }
            percentage
          />
        </div>
        <div className="NumbersSecondary">
          <HeadingWithTitle
            title="GRARC"
            id="Renewal-Adjustment-Card-Heading-GRARC"
            value={grarc}
            decimals={2}
            className="GRARC"
            prefix={undefined}
            suffix="%"
          />
          <HeadingWithTitle
            title="Adjusted GWP"
            id="Renewal-Adjustment-Card-Header-Adjusted-GWP"
            value={
              filteredRenewalKpis?.[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN]?.[props.adjustmentBeingEditedId] || ZERO
            }
            decimals={0}
            className="Adjusted"
            prefix="$"
            suffix={
              filteredRenewalKpis &&
              filteredRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN] &&
              filteredRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][props.adjustmentBeingEditedId]
                ? suffixForValue(
                    filteredRenewalKpis[TOTAL_ADJUSTED_GWP_PER_ADJUSTMENT_COLUMN][props.adjustmentBeingEditedId]!,
                  )
                : ''
            }
          />
        </div>
        <div className="BottomOptions">
          <TextField
            title="Name"
            id="Renewal-Adjustment-Card-TextField-Name"
            placeholder="Name"
            value={currentName}
            onChange={onInputUpdateState(setName)}
            error={shouldValidate ? (isValidName(currentName) ? '' : 'Please enter a name for the adjustment') : ''}
            className="AdjustmentName"
          />
          <TextArea
            className="AdjustmentDescription"
            id="Renewal-Adjustment-Card-TextField-Adjustment-Description"
            title="Description"
            placeholder="Description"
            value={currentDescription}
            onChange={onTextAreaUpdateState(setDescription)}
            error={shouldValidate ? validateDescription(currentDescription) : ''}
          />
        </div>
        <div className="ButtonContainer">
          {props.adjustmentBeingEditedId !== PREVIEW_ID && (
            <Button
              title="Cancel"
              id="Renewal-Adjustment-Card-Button-Cancel"
              secondary
              onClick={cancelEditing}
            />
          )}
          <Button
            title={addOrSaveWord}
            id="Renewal-Adjustment-Card-Button-Add-Or-Save"
            onClick={validateThenCreateOrUpdateAdjustment}
          />
        </div>
        {shouldValidate &&
          (numberOfItemsRelevantToAdjustmentId === 0 || clientSpecificErrorText || errorUniqueName.length > 0) && (
            <div className="CreateAdjustmentCardErrorText">
              <ErrorText error="You can't create an adjustment that affects 0 records" />
              {shouldValidate && clientSpecificErrorText && <ErrorText error={clientSpecificErrorText} />}
              {shouldValidate && errorUniqueName.length > 0 && <ErrorText error={errorUniqueName} />}
            </div>
          )}
      </Card>
    </div>
  )
}
