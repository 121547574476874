import { numericValueIsDefined, PossiblyNegativeNumber } from '../../../utils/numbers'
import { ManyDropdownFilterValues } from '../../../providers/FilterProvider/model'
import { CLIENT_NAME_FILTER_ID, CLIENT_PARENT_FILTER_ID } from '../../../pages/client/filterIds'
import { LESS_THAN_NEGATIVE_100_ERROR, MORE_THAN_100_WARNING } from './messages'

export const calculatePureGrossRateChangeWarning = (currentPureGrossRateChange: PossiblyNegativeNumber) => {
  if (
    currentPureGrossRateChange &&
    typeof currentPureGrossRateChange === 'number' &&
    currentPureGrossRateChange > 100
  ) {
    return MORE_THAN_100_WARNING
  }

  return undefined
}

export const calculatePureGrossRateChangeError = (currentPureGrossRateChange: PossiblyNegativeNumber) => {
  if (!numericValueIsDefined(currentPureGrossRateChange)) {
    return 'This must have a value'
  }
  if (
    currentPureGrossRateChange &&
    typeof currentPureGrossRateChange === 'number' &&
    currentPureGrossRateChange < -100
  ) {
    return LESS_THAN_NEGATIVE_100_ERROR
  }

  return undefined
}

export const calculateRiskAdjustedPremiumRateChangeWarning = (
  currentRiskAdjustedPremiumRateChange: PossiblyNegativeNumber,
) => {
  if (
    currentRiskAdjustedPremiumRateChange &&
    typeof currentRiskAdjustedPremiumRateChange === 'number' &&
    currentRiskAdjustedPremiumRateChange > 100
  ) {
    return MORE_THAN_100_WARNING
  }

  return undefined
}

export const calculateRiskAdjustedPremiumRateChangeError = (
  currentRiskAdjustedPremiumRateChange: PossiblyNegativeNumber,
) => {
  if (!numericValueIsDefined(currentRiskAdjustedPremiumRateChange)) {
    return 'This must have a value'
  }
  if (
    currentRiskAdjustedPremiumRateChange &&
    typeof currentRiskAdjustedPremiumRateChange === 'number' &&
    currentRiskAdjustedPremiumRateChange < -100
  ) {
    return LESS_THAN_NEGATIVE_100_ERROR
  }

  return undefined
}

export const calculateConvexShareChangeWarning = (currentConvexShareChange: PossiblyNegativeNumber) => {
  if (currentConvexShareChange && typeof currentConvexShareChange === 'number' && currentConvexShareChange > 100) {
    return MORE_THAN_100_WARNING
  }

  return undefined
}

export const calculateConvexShareChangeErrorRealTime = (currentConvexShareChange: PossiblyNegativeNumber) => {
  if (currentConvexShareChange && typeof currentConvexShareChange === 'number' && currentConvexShareChange < -100) {
    return "This can't be less than 100"
  }

  return undefined
}

export const calculateConvexShareChangeError = (currentConvexShareChange: PossiblyNegativeNumber) => {
  if (!numericValueIsDefined(currentConvexShareChange)) {
    return 'This must have a value'
  }

  return undefined
}

export const calculateRetentionChangeErrorRealTime = (currentRetentionChange: PossiblyNegativeNumber) => {
  if (currentRetentionChange && typeof currentRetentionChange === 'number' && currentRetentionChange < 0) {
    return "This can't be less than 0"
  }

  if (currentRetentionChange && typeof currentRetentionChange === 'number' && currentRetentionChange > 100) {
    return "This can't be greater than 100"
  }

  return undefined
}

export const calculateRetentionChangeError = (currentRetentionChange: PossiblyNegativeNumber) => {
  if (!numericValueIsDefined(currentRetentionChange)) {
    return 'This must have a value'
  }

  if (
    currentRetentionChange &&
    typeof currentRetentionChange === 'number' &&
    (currentRetentionChange < 0 || currentRetentionChange > 100)
  ) {
    return 'Must be between 0 and 100'
  }

  return undefined
}

export const calculateErrorIfIsClientSpecificAndNoClientSelected = (
  adjustmentType: string,
  filterOptions: ManyDropdownFilterValues,
) => {
  if (adjustmentType !== 'CLIENT_SPECIFIC') {
    return undefined
  }

  const clientSpecificFilter = filterOptions[CLIENT_NAME_FILTER_ID]
  const clientParentSpecificFilter = filterOptions[CLIENT_PARENT_FILTER_ID]
  if (!clientSpecificFilter?.chosenValue && !clientParentSpecificFilter?.chosenValue) {
    return 'Please select a client first using the filters'
  }

  return undefined
}
